import "../../styles/HSDatePicker.less";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useState, useRef, useEffect } from "react";
import { HSNewCalendarIcon } from "./Icons";
import { Tooltip, DatePicker } from "antd";
import { getDateFormat } from "@commscopemycloud/humaui/Utilities/DateTime";
dayjs.extend(utc);
dayjs.extend(timezone);

export const HSDatePicker = (props) => {
  const datePickerRef = useRef(null);
  const wrapperRef = useRef(null); // Ref for the wrapper div
  const [pickerOpen, setPickerOpen] = useState(false);
  const { onDateSelect, DateFormat, required, timeZone, defaultValue, defaultText, name, disabledDate, value} = props;
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const handleDateSelect = (value) => {
    setPickerOpen(false);
    setSelectedDate(value)
    onDateSelect(value);
  };
  const handleOnBlur = ()=>{
    setPickerOpen(false);
  }
  useEffect(() => {
    datePickerRef && datePickerRef.current.focus();
  });  
  const handleWrapperClick = () => {
    if (!pickerOpen) {
      setPickerOpen(true);
    }
  };
  const handleWrapperBlur = (event) => {
    // Check if the blur event is happening outside the DatePicker and wrapper
    const isDatePickerButton = event.target.classList.contains('ant-picker-header-super-prev-btn') ||
                               event.target.classList.contains('ant-picker-header-super-next-btn') ||
                               event.target.closest('.ant-picker-panel');
    if (!wrapperRef.current.contains(event.target) && datePickerRef.current !== event.target && !isDatePickerButton) {
      handleOnBlur(); // Call handleOnBlur if clicked outside DatePicker and wrapper
    }
  };

  const onOpenChangePicker = (pickerOpen) => {
    if(!pickerOpen){
      setPickerOpen(false);
    }
  }
  
  const dateFormat = selectedDate ? getDateFormat(selectedDate,timeZone) : "";
  let day = dateFormat ? dateFormat.day : defaultText;
  day = dateFormat.dateFormat ? day : defaultText;
  return (
    <div  className="hs-datepicker-wrapper" ref={wrapperRef} onClick={handleWrapperClick} onBlur={handleWrapperBlur}>
      <div className="hs-datepicker-content">
        <div className="hs-datepicker-date">
          <span className="hs-datepicker-day">
            {day}
          </span>
          &nbsp;{dateFormat.dateFormat ? dateFormat.dateFormat : ""}
          <div className="hs-datepicker-calendar">
            <Tooltip title="Select Date">
              <div className="action">
                <DatePicker
                  ref={datePickerRef}
                  className={!pickerOpen ? "hs-datepicker-hide" : ""}
                  required={required}
                  allowClear={false}
                  open={pickerOpen}
                  name={name}
                  disabledDate={disabledDate}
                 // defaultValue={defaultValue ? dayjs(defaultValue) : dayjs(new Date().setHours(0,0,0,0))}
                  format={DateFormat}
                  onChange={(value) => handleDateSelect(value)}
                  onOpenChange={onOpenChangePicker}
                  // onBlur={handleOnBlur}
                  value={value}
                  />
                <div><HSNewCalendarIcon /></div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
