/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { message } from "antd";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";

const DefaultNumberOfDays = 7;
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const NOTIFICATIONS_NUMBER_OF_DAYS = ENV_VARS.NOTIFICATIONS_NUMBER_OF_DAYS;

const useNotification = ({
  useruuid,
  startdatetime,
  enddatetime,
  notificationtypes,
  details = true,
  callhistorycheck = false,
}) => {
  const [data, setData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const trans = useSelector(translator);
  const notificationApi = useSelector((state) => state.apis.notificationApi);

  const fetchData = () => {
    const errorCallback = (error) => {
      setFetchingData(false);
      message.error("Error fetching notifications!");
      console.error("Error fetching notifications:", error);
    };
    const successCallback = (data) => {
      setFetchingData(false);
      console.info("Notifications: fetch result", data);
      const notificationData = callhistorycheck
        ? data?.notificationlist ?? []
        : data?.notifications ?? [];
      setData(notificationData);
    };
    try {
      const days = NOTIFICATIONS_NUMBER_OF_DAYS ?? DefaultNumberOfDays;
      let startDateTime =
        startdatetime || moment().subtract(days, "day").format();
      let endDateTime = enddatetime || moment().format();
      /* Notification api requires datetime in UTC */
      startDateTime = moment(startDateTime).utc().format();
      endDateTime = moment(endDateTime).utc().format();
      let notificationTypes = callhistorycheck
        ? ["call_status"]
        : ["call_status", "calendar", "contact"];
      if (!useruuid) return;
      setData([]);
      setFetchingData(true);
      notificationApi.getNotificationsByTimeRange(
        useruuid,
        startDateTime,
        endDateTime,
        { notificationtypes: notificationTypes },
        { details },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(
    () => fetchData(),
    [useruuid, startdatetime, enddatetime, notificationtypes, callhistorycheck]
  );

  return [data, fetchingData, fetchData];
};

export default useNotification;
