/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { AddEvent } from "./AddEvent";
import {
  Actions,
  UserRoles,
  UserTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { useSelector } from "react-redux";
import useContacts from "../Hooks/useContacts";
import ListSchedule from "./ListSchedule";
import { StartEvent } from "./StartEvent";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useUserInfo from "../Hooks/useUserInfo";
import { DashboardIcon } from "../Common/Icons";
import useStaffList from "../Hooks/useStaffList";
import useSchedule from "../Hooks/useSchedule";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { useLocation } from "react-router-dom";
import Task from "../Task/Task";

const Schedule = (props) => {
  console.debug("Schedule props", props);
  const {
    onlyContent,
    parentBread,
    hubRecord,
    eventRecord,
    userHubsData,
    rolename,
    onActionClick: parentActionClick,
    openSubMenu,
    showHeader,
    activeKey,
    userHubCall,
    handleTabChange,
  } = props;
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const [fetchingScheduleList, scheduleList, fetchSchedule] = useSchedule();
  const isStaff = useMemo(
    () =>
      hubRecord
        ? hubRecord.rolename === UserRoles.staff || hubRecord.staffadmin
        : currentUser?.usertype === UserTypes.staff,
    [hubRecord]
  );

  const useruuid = hubRecord?.useruuid ?? currentUser?.useruuid;
  const [contacts, fetchingContacts] = useContacts({ useruuid });
  const [userInfo] = useUserInfo({ useruuid });
  const [staffList, fetchingStaffList] = useStaffList({
    useruuid: isStaff ? useruuid : null,
  });

  const [action, setAction] = useState(props.action);
  const [record, setRecord] = useState(eventRecord);
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  useEffect(() => fetchSchedule(useruuid, selectedDate, 7), []);
  useEffect(() => setAction(props.action), [props.action]);
  useEffect(() => setRecord(props.eventRecord), [props.eventRecord]);

  useEffect(() => {
    if (selectedDate) fetchSchedule(useruuid, selectedDate, 7);
  }, [selectedDate]);

  let { state } = useLocation();
  useEffect(() => {
    if (state != null) {
      state.action && setAction(state.action);
      state.eventRecord && setRecord(state.eventRecord);
    }
  }, [state]);

  const onActionClick =
    (action, record, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      refresh && fetchSchedule(useruuid, selectedDate, 7);
    };

  // const removeTimeZone = (str) => {
  //   if (!str) return null;
  //   const index = str.indexOf("(");
  //   if (index !== -1) {
  //     return str.substring(0, index).trim();
  //   }
  //   return str;
  // };

  const header = (parentBread || []).concat(
    parentActionClick
      ? {
          label: trans("SCHEDULE"),
          onClick: parentActionClick(null, null),
        }
      : {
          label: trans("DASHBOARD"),
          icon: <DashboardIcon />,
          onClick: onActionClick(null, null),
        }
  );

  return !action ? (
    <ListSchedule
      hubRecord={hubRecord}
      parentBread={header}
      onlyContent={onlyContent}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      scheduleList={scheduleList}
      contacts={contacts}
      userInfo={userInfo}
      fetchingScheduleList={fetchingScheduleList}
      onActionClick={(activeKey ? null : parentActionClick) || onActionClick}
      rolename={rolename}
      openSubMenu={openSubMenu}
    />
  ) : action === Actions.addEvent || action === Actions.editEvent ? (
    <AddEvent
      onlyContent={onlyContent}
      parentBread={header}
      action={action}
      hubRecord={hubRecord}
      eventRecord={record}
      userInfo={userInfo}
      contacts={contacts}
      fetchingContacts={fetchingContacts}
      staffList={staffList}
      fetchingStaffList={fetchingStaffList}
      onActionClick={((activeKey === "contacts"? true : userHubCall) && activeKey ? parentActionClick : null ) || onActionClick}
      openSubMenu={openSubMenu}
      showHeader={showHeader}
      userHubCall={userHubCall}
      handleTabChange={handleTabChange}
      activeKey={activeKey}
    />
  ) : action === Actions.addTask ? (
    <Task
      onlyContent={true}
      action={action}
      hubRecord={hubRecord}
      userInfo={userInfo}
      onActionClick={(activeKey ? null : parentActionClick) || onActionClick}
    />
  ) : action === Actions.startEvent ? (
    <StartEvent
      onlyContent={onlyContent}
      parentBread={header}
      hubRecord={hubRecord}
      onActionClick={(activeKey ? null : parentActionClick) || onActionClick}
      eventRecord={record}
      userHubsData={userHubsData}
      userInfo={userInfo}
      rolename={rolename}
      openSubMenu={openSubMenu}
    />
  ) : null;
};

Schedule.defaultProps = {
  onlyContent: false,
  parentBread: [],
};

Schedule.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
};

export default Schedule;
