import React from "react";

export const BatteryHighIcon = (props) => {
  const {
    className = "",
    style = {},
    disabled = false,
    onClick,
    connectionStatus,
  } = props;
  return (
    <svg
      className={className}
      style={style}
      width="63"
      height="60"
      viewBox="0 0 63 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="30" cy="30" rx="30" ry="30" fill="#F5F5F5" />
      {connectionStatus === "CONNECTED" ? (
        <circle cx="52.5" cy="49.5" r="7.5" fill="#4BD37B" />
      ) : (
        <svg>
          <circle
            cx="52.5"
            cy="49.5"
            r="7.5"
            fill="white"
            stroke="grey"
            strokeWidth="1"
          />
          <line x1="49" y1="46" x2="56" y2="53" stroke="grey" strokeWidth="1" />
          <line x1="56" y1="46" x2="49" y2="53" stroke="grey" strokeWidth="1" />
        </svg>
      )}
      <path
        d="M36.7402 15H22.2689C21.574 15 21 15.574 21 16.2689V38.5045C21 42.0997 23.9154 45 27.4955 45H31.4985C35.0937 45 37.994 42.0846 37.994 38.5045V16.2689C37.994 15.574 37.4199 15 36.7251 15H36.7402ZM29.6103 41.5106C27.0121 41.5106 24.9124 39.4109 24.9124 36.8127C24.9124 34.2145 27.0121 32.1148 29.6103 32.1148C32.2085 32.1148 34.3082 34.2145 34.3082 36.8127C34.3082 39.4109 32.2085 41.5106 29.6103 41.5106Z"
        fill="#113E57"
      />
      <path
        d="M29.6405 34.5615C28.4019 34.5615 27.3898 35.5736 27.3898 36.8123C27.3898 38.0509 28.4019 39.063 29.6405 39.063C30.8792 39.063 31.8913 38.0509 31.8913 36.8123C31.8913 35.5736 30.8792 34.5615 29.6405 34.5615Z"
        fill="#113E57"
      />
      <mask
        id="mask0_13571_10022"
        /* style="mask-type:alpha" */ maskUnits="userSpaceOnUse"
        x="43"
        y="17"
        width="20"
        height="20"
      >
        <rect x="43" y="17" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13571_10022)">
        <path
          d="M48.8333 35.6667C48.5972 35.6667 48.3993 35.5868 48.2396 35.4271C48.0799 35.2674 48 35.0694 48 34.8333V21.5C48 21.2639 48.0799 21.066 48.2396 20.9063C48.3993 20.7465 48.5972 20.6667 48.8333 20.6667H50.5V19H53.8333V20.6667H55.5C55.7361 20.6667 55.934 20.7465 56.0938 20.9063C56.2535 21.066 56.3333 21.2639 56.3333 21.5V34.8333C56.3333 35.0694 56.2535 35.2674 56.0938 35.4271C55.934 35.5868 55.7361 35.6667 55.5 35.6667H48.8333Z"
          fill="#4BD37B"
        />
      </g>
    </svg>
  );
};
