import React, { useEffect, useState, useRef, useMemo } from "react";
import "./MotionActivity.less";
import {
  MotionActivityIcon2,
  NewHC200Icon,
  ArrowRightIcon,
  ArrowLeftIcon,
  MorningIcon,
  NightIcon,
  MotionEmpty,
  BatteryHighIcon,
  BatteryMediumIcon,
  BatteryLowIcon,
} from "../Common/Icons";
import { Spin } from "antd";
import MotionCalendar from "./MotionCalendar";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import {
  AppStorageKeys,
  DefaultTimezone,
} from "@commscopemycloud/humaui/Utilities/Constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const MOTION_RANGES = SessionStorage.get(AppStorageKeys?.motionRanges);

const timeArray = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

const get_utc_offset = (timeZone) => {
  timeZone = timeZone?.split(" ")[0] || DefaultTimezone;
  return Intl.DateTimeFormat(navigator.language, {
    timeZoneName: "longOffset",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName")
    .value.replace("GMT", "");
};

const MotionActivity = ({ hubRecord, timezone, deviceStatus }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateToDisplay, setDateToDisplay] = useState(new Date().toDateString());
  const timezoneOffset = useMemo(() => get_utc_offset(timezone), [timezone]);
  const [activityDateStart, setActivityDateStart] = useState(
    moment().startOf("day").format()
  );
  const [activityDateEnd, setActivityDateEnd] = useState(
    moment().endOf("day").format()
  );
  const [shiftDate, setShiftDate] = useState(null);
  const [dataPerHour, setDataPerHour] = useState(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [allDevicesFetched, setAllDevicesFetched] = useState({});
  const calendarRef = useRef(null);

  const smallRangeMin = MOTION_RANGES?.SMALL_RANGE_MIN || 0;
  const smallRangeMax = MOTION_RANGES?.SMALL_RANGE_MAX || 50;
  const smallRangeVal = MOTION_RANGES?.SMALL_RANGE_VALUE || 8;
  const mediumRangeMin = MOTION_RANGES?.MEDIUM_RANGE_MIN || 50;
  const mediumRangeMax = MOTION_RANGES?.MEDIUM_RANGE_MAX || 100;
  const mediumRangeVal = MOTION_RANGES?.MEDIUM_RANGE_VALUE || 13;
  const largeRangeMin = MOTION_RANGES?.LARGE_RANGE_MIN || 100;
  const largeRangeMax = MOTION_RANGES?.LARGE_RANGE_MAX || 200;
  const largeRangeVal = MOTION_RANGES?.LARGE_RANGE_VALUE || 16;
  const xLargeRangeMin = MOTION_RANGES?.XLARGE_RANGE_MIN || 200;
  const xLargeRangeVal = MOTION_RANGES?.XLARGE_RANGE_VALUE || 20;

  const motionBubble = (occ) => {
    if (occ > smallRangeMin && occ < smallRangeMax)
      return smallRangeVal; // small
    else if (occ >= mediumRangeMin && occ < mediumRangeMax)
      return mediumRangeVal; // medium
    else if (occ >= largeRangeMin && occ < largeRangeMax)
      return largeRangeVal; // large
    else if (occ >= xLargeRangeMin) return xLargeRangeVal; //x-large
  };

  const filterBySelectedDate = (data, date) => {
    const filteredData = data.filter((obj) => {
      return obj.utc_timestamp_inms.split("T")[0] === date;
    });
    return filteredData;
  };

  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const trans = useSelector(translator);

  const localTimeOffset = () => {
    const offsetSign = timezoneOffset.startsWith("+") ? 1 : -1;
    const [hours, minutes] = timezoneOffset.substring(1).split(":").map(Number);
    return [hours * offsetSign, minutes * offsetSign];
  };

  const dataAccumulatedByHour = (data) => {
    return data.reduce((acc, obj) => {
      const hour = moment
        .tz(obj.utc_timestamp_inms, timezone.split(" ")[0])
        .hour();

      const sumCounts = obj.detection_counts
        .split(",")
        .map(Number)
        .reduce((sum, count) => sum + count, 0);

      acc[hour] = (acc[hour] || 0) + sumCounts;
      return acc;
    }, {});
  };

  const convertToTimezoneOffset = (timestamp, offset) => {
    const date = new Date(timestamp);
    date.setUTCMinutes(date.getUTCMinutes() + offset);
    return date.toISOString().replace("Z", timezoneOffset);
  };

  const updateEventsData = (eventsData) => {
    const [localHourOffset, localMinuteOffset] = localTimeOffset();
    const timezoneOffset =
      localHourOffset * 60 +
      (localHourOffset < 0 ? -localMinuteOffset : localMinuteOffset);

    return eventsData.map((eventData) => ({
      ...eventData,
      start_time: convertToTimezoneOffset(eventData.start_time, timezoneOffset),
      end_time: convertToTimezoneOffset(eventData.end_time, timezoneOffset),
      utc_timestamp_inms: convertToTimezoneOffset(
        eventData.utc_timestamp_inms,
        timezoneOffset
      ),
    }));
  };

  const separateDeviceData = (data) => {
    const map = {};
    data.forEach((obj) => {
      if (!map[obj.detection_device]) {
        map[obj.detection_device] = [];
      }
      map[obj.detection_device].push(obj);
    });
    return map;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const sortEventsByStartTime = (events) =>
    events.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

  const accumulateDataPerHour = (devicesData) => {
    return Object.entries(devicesData).reduce((acc, [deviceuuid, events]) => {
      const { detection_type, location } = events[0];
      const device = allDevicesFetched[deviceuuid];

      acc[deviceuuid] = {
        location,
        type: detection_type,
        hourlyData: dataAccumulatedByHour(events),
        batteryLevel: device?.battery_level,
        connectionStatus: device?.connectionStatus,
        model: device?.modelnumber,
      };

      return acc;
    }, {});
  };

  const getAllDevicesDataPerHour = (combinedDataPerHour) => {
    if (!Object.keys(allDevicesFetched).length) return;

    const devicesDataUuid = Object.keys(combinedDataPerHour);
    const allDevicesUuid = Object.keys(allDevicesFetched);

    const missingDevices = allDevicesUuid.filter(
      (key) => !devicesDataUuid.includes(key)
    );
    for (const deviceuuid of missingDevices) {
      const deviceData = allDevicesFetched[deviceuuid];

      combinedDataPerHour[deviceuuid] = {
        location: deviceData.location,
        type: "default",
        hourlyData: {},
        batteryLevel: deviceData.battery_level,
        connectionStatus: deviceData.connectionStatus,
        model: deviceData.modelnumber,
      };
    }

    return combinedDataPerHour;
  };

  const processMotionData = (data) => {
    try {
      const eventsData = data.user_events;
      const eventsDataPrincipalTimezone = updateEventsData(eventsData);
      const dateFormatted = formatDate(dateToDisplay);
      const dataFilteredByDate = filterBySelectedDate(
        eventsDataPrincipalTimezone,
        dateFormatted
      );
      const sortedData = sortEventsByStartTime(dataFilteredByDate);
      const devicesData = separateDeviceData(sortedData);

      const combinedDataPerHour = Object.keys(devicesData).length
        ? accumulateDataPerHour(devicesData)
        : {};

      const updatedData = ensureLastHourInData(combinedDataPerHour);

      const allDevicesDataPerHour = getAllDevicesDataPerHour(updatedData);

      setDataPerHour(allDevicesDataPerHour);
      console.log(allDevicesDataPerHour);
      setDataLoaded(true);
    } catch (error) {
      console.error("Error processing motion data:", error);
      setDataLoaded(false);
    }
  };

  const ensureLastHourInData = (combinedDataPerHour) => {
    const userIds = Object.keys(combinedDataPerHour);
    const hourlyData = userIds.map((id) => combinedDataPerHour[id].hourlyData);

    const maxHours = hourlyData.map((obj) =>
      Math.max(...Object.keys(obj).map(Number))
    );
    const maxHour = Math.max(...maxHours);

    userIds.forEach((id) => {
      const obj = combinedDataPerHour[id];
      const lastHour = Math.max(...Object.keys(obj.hourlyData).map(Number));
      if (lastHour < maxHour) {
        obj.hourlyData[maxHour] = 0;
      }
    });

    return combinedDataPerHour;
  };

  const sortByModelAndLocation = (data) => {
    return Object.keys(data).sort((a, b) => {
      const itemA = data[a];
      const itemB = data[b];

      if (itemA.model === "HC200") return -1;
      if (itemB.model === "HC200") return 1;

      const locationA = itemA.location || "";
      const locationB = itemB.location || "";
      return locationA.localeCompare(locationB);
    });
  };

  const fetchMotionData = () => {
    setDataLoaded(false);
    const applyTimezoneOffset = (date) =>
      date.replace(/Z|(\+|-)\d\d:\d\d$/, timezoneOffset);
    const activityDateStartWithOffset = applyTimezoneOffset(activityDateStart);
    const activityDateEndWithOffset = applyTimezoneOffset(activityDateEnd);

    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("motion data", data);
      processMotionData(data);
    };

    try {
      userEventsApi.userMotionEventsByTimeRange(
        hubRecord.useruuid,
        activityDateStartWithOffset,
        activityDateEndWithOffset,
        {},
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const dayORNight = (hour) => {
    let time = hour.split(" ")[0];
    let period = hour.split(" ")[1];
    if (time === "12") time = 0;
    if ((period === "AM" && time <= 6) || (period === "PM" && time >= 6)) {
      return "night";
    } else {
      return "day";
    }
  };

  const convertTo24HourFormat = (time) => {
    let hour = parseInt(time, 10);
    if (hour === 12) {
      hour = 0;
    }
    if (time.includes("PM")) {
      hour += 12;
    }
    return hour.toString();
  };

  const renderMotionAsPercent = (hoursIn24Format, dataPerHour) => {
    if (dataPerHour == null) return;
    var visible = "";

    const radiusBubble = dataPerHour.hasOwnProperty(hoursIn24Format)
      ? motionBubble(dataPerHour[hoursIn24Format])
      : (visible = "hidden");

    return visible === "hidden" ? (
      <div
        className="motion-empty-block"
        style={{
          border: "0px",
          borderRadius:
            hoursIn24Format === "0"
              ? "5px 0px 0px 5px"
              : hoursIn24Format === "23"
              ? "0px 5px 5px 0px"
              : "",
        }}
      >
        <MotionEmpty style={{ margin: "6px" }} />
      </div>
    ) : (
      <div className="motion-percent-block">
        <div
          className="motion-bubble"
          style={{
            width: `${radiusBubble}px`,
            height: `${radiusBubble}px`,
          }}
        ></div>
      </div>
    );
  };

  const renderMorningNightIcon = (hour) => {
    const icon =
      hour === "7 AM" ? (
        <MorningIcon />
      ) : hour === "6 PM" ? (
        <NightIcon />
      ) : null;

    return <div style={{ width: "12px", height: "16px" }}>{icon}</div>;
  };

  const lastHourEntry = useMemo(() => {
    if (!dataPerHour || Object.keys(dataPerHour).length === 0) return -1;

    const [{ hourlyData = {} } = {}] = Object.values(dataPerHour);
    const keys = Object.keys(hourlyData);

    if (!keys.length) return -1;
    const lastKey = parseInt(keys[keys.length - 1], 10);
    return isNaN(lastKey) ? -1 : lastKey;
  }, [dataPerHour]);

  const renderHours = (hour, dataPerHour, lastHourEntry) => {
    if (dataPerHour === null) return;
    const hoursIn24Format = convertTo24HourFormat(hour);

    const currentDay = moment.tz(timezone.split(" ")[0]).format("YYYY-MM-DD");
    const displayDay = moment(dateToDisplay).format("YYYY-MM-DD");

    const isToday = currentDay === displayDay;
    const isLaterHour = isToday && parseInt(hoursIn24Format) > lastHourEntry;

    const hasZeroData =
      dataPerHour.hasOwnProperty(hoursIn24Format) &&
      dataPerHour[hoursIn24Format] === 0;

    return (
      <div key={hour}>
        <div className="main-hour-container" style={{ padding: "1px" }}>
          <div className="day-night-theme">{renderMorningNightIcon(hour)}</div>
          <div className="day-night-time">{hour}</div>
          {!isLaterHour && (
            <div
              className={`${
                dayORNight(hour) === "night"
                  ? "night-time-block"
                  : "day-time-block"
              }`}
              style={{
                borderRadius:
                  hour === "12 AM"
                    ? "5px 0px 0px 5px"
                    : hour === "11 PM"
                    ? "0px 5px 5px 0px"
                    : "",
              }}
            >
              <div className="motion-activity-block">
                {!hasZeroData
                  ? renderMotionAsPercent(hoursIn24Format, dataPerHour)
                  : void 0}{" "}
              </div>
            </div>
          )}
          {isLaterHour && (
            <div
              className="future-hour-block"
              style={{
                borderRadius:
                  hour === "12 AM"
                    ? "5px 0px 0px 5px"
                    : hour === "11 PM"
                    ? "0px 5px 5px 0px"
                    : "",
              }}
            ></div>
          )}
        </div>
      </div>
    );
  };

  const renderActivityItem = (key) => {
    const item = dataPerHour[key];
    return (
      <div className="main-activity-container" key={key}>
        {renderDeviceIcon(
          item.location,
          item.model,
          item.connectionStatus,
          item.batteryLevel
        )}
        <div className="motion-activity-container">
          {renderMotionActivity(item)}
        </div>
      </div>
    );
  };

  const DeviceInfoContainer = ({ deviceLocation, children }) => (
    <div className="device-info-container">
      <div className="device-info-header">
        {deviceLocation &&
          deviceLocation
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
      </div>
      <div className="device-icon">{children}</div>
    </div>
  );

  const renderDeviceIcon = (
    deviceLocation,
    deviceModel,
    connectionStatus,
    batteryLevel
  ) => {
    let icon;
    if (deviceModel === "HC200") {
      icon = <NewHC200Icon status={deviceStatus} className="icon" />;
    } else {
      if (batteryLevel < 35) {
        icon = <BatteryLowIcon connectionStatus={connectionStatus} />;
      } else if (batteryLevel <= 49) {
        icon = <BatteryMediumIcon connectionStatus={connectionStatus} />;
      } else {
        icon = <BatteryHighIcon connectionStatus={connectionStatus} />;
      }
    }

    return (
      <DeviceInfoContainer deviceLocation={deviceLocation}>
        {icon}
      </DeviceInfoContainer>
    );
  };

  const renderMotionActivity = (deviceData) => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {timeArray.map((hour) =>
          renderHours(hour, deviceData.hourlyData, lastHourEntry)
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!!Object.keys(allDevicesFetched).length) {
      fetchMotionData();
    }
  }, [allDevicesFetched]);

  const fetchUserAccessories = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("UserAccessories data", data);
      const deviceObject = data["user_accessories"].reduce((obj, device) => {
        if (device.modelnumber === "HC200" || device.modelnumber === "EM200") {
          obj[device.deviceuuid] = {
            modelnumber: device.modelnumber,
            location: device.location,
            battery_level: device?.battery_level,
            connectionStatus:
              device.modelnumber === "HC200"
                ? device.deviceconnectstatus
                : device.connected
                ? "CONNECTED"
                : "DISCONNECTED",
          };
        }
        return obj;
      }, {});
      console.log("Devices fetched", deviceObject);
      setAllDevicesFetched(deviceObject);
    };

    try {
      userEventsApi.getUserAccessories(
        hubRecord.useruuid,
        {
          eventtypes: ["environment_status"],
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    fetchUserAccessories();
  }, [activityDateStart, activityDateEnd]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleArrowVisibility = (date) => {
    const today = moment().startOf("day");

    const shouldHideRightArrow = moment(date).isSame(today, "day");
    const shouldHideLeftArrow = moment(date).isSame(
      today.clone().subtract(1, "month"),
      "day"
    );

    setHideRightArrow(shouldHideRightArrow);
    setHideLeftArrow(shouldHideLeftArrow);
  };

  const onActionClick =
    (date, activityDateStart, activityDateEnd, toggle = false) =>
    () => {
      setDataLoaded(false);
      console.log("selected date", date);
      if (!!date) {
        toggleArrowVisibility(date);
        setDateToDisplay(date.toDateString());
        setActivityDateStart(activityDateStart);
        setActivityDateEnd(activityDateEnd);
      }
      toggle && toggleCalendar();
    };

  const handleArrowClick = (direction) => {
    setDataLoaded(false);
    const currentDate = moment(dateToDisplay);
    const nextDate =
      direction === "forward"
        ? currentDate.add(1, "day").toDate()
        : currentDate.subtract(1, "day").toDate();

    setDateToDisplay(nextDate.toDateString());
    setActivityDateStart(moment(nextDate).startOf("day").format());
    setActivityDateEnd(moment(nextDate).endOf("day").format());
    setShiftDate(direction);
    toggleArrowVisibility(nextDate);
  };

  const handleClickOutsideCalendar = (e) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, []);

  return (
    <div style={{ fontFamily: "Euclid Circular" }}>
      <div className="motion-activity-header">
        <MotionActivityIcon2 />
        <div className="motion-activity-title">{trans("MOTIONACTIVITY")}</div>
      </div>
      <div className="motion-calendar-container">
        <div className="motion-calendar-date-display">{dateToDisplay}</div>
        <div className="calendar-nav-icons">
          <div className="arrow-icons">
            {!hideLeftArrow && (
              <ArrowLeftIcon
                className="calendar-nav-icon"
                onClick={() => handleArrowClick("back")}
              />
            )}
          </div>
          <button onClick={toggleCalendar} className="button-select-date">
            {showCalendar ? "Select Date" : "Select Date"}
          </button>
          <div className="arrow-icons">
            {!hideRightArrow && (
              <ArrowRightIcon
                className="calendar-nav-icon"
                onClick={() => handleArrowClick("forward")}
              />
            )}
          </div>
        </div>
        {showCalendar && (
          <div ref={calendarRef}>
            <MotionCalendar
              onActionClick={onActionClick}
              shiftDate={shiftDate}
              dateToDisplay={dateToDisplay}
            />
          </div>
        )}
      </div>
      <Spin spinning={!dataLoaded} style={{ marginTop: "100px" }}>
        {dataLoaded &&
          sortByModelAndLocation(dataPerHour).map(renderActivityItem)}
      </Spin>
    </div>
  );
};

export default MotionActivity;
