import { AutoCallIcon } from "../Common/Icons";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import React from "react";

export const getModalDataForCall = (calleesValue,cancelCallModal,dispatch,trans) => ({
  icon: <AutoCallIcon className="auto-call-icon" />,
  title: <div className="auto-call-title">{trans("AUTO_ANSWER")}</div>,
  description: <div className="auto-call-description">{trans("AUTO_ANSWER_DESCRIPTION")}</div>,
  subdescription: <div className="sub-call-description">{""}</div>,
  cancel: cancelCallModal,
  ring: () => {
    dispatch(startVideoCall({ callees: [calleesValue], calleesValue }));
    cancelCallModal();
  },
  proceed: () => {
      dispatch(startVideoCall({
        callees: [calleesValue],
        calleesValue,
        autoAccept: true,
      }));
      cancelCallModal();
  }
});
