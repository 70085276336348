/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { Divider, Empty, Input, Skeleton, Tooltip } from "antd";
import {
  ContactIcon,
  CallIcon,
  BadgeAddIcon,
  HC200Icon,
  ManageIcon,
  CareIcon,
  SocialIcon,
  ContactImage,
  SearchIcon,
  FilterIcon,
  CloseIcon,
  SyncIcon,
  AutoAnswerIcon,
  NewHC200Icon,
  AddNewScheduleIcon,
  NewSocialIcon,
  NewContactIcon,
  NewProfileIcon,
} from "../Common/Icons";
import MainPage from "../../MainPage";
import CustomDropdown from "../Common/CustomDropdown";
import {
  allowAutoVideoCall,
  getUsername,
  isCare,
  isManage,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  Actions,
  UserPermissionLevel,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Access,
  checkRoleAccess,
  KnownActionsList,
  isActionAllowed,
  isStaffActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { PhotoResolution } from "@commscopemycloud/humaui/Utilities/Constants";
import UserHubModal from "./UserHubModal";
import RebootResetModal from "../Devices/RebootResetModal";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import AutoCallModal from  "../Modals/AutoCallModal";
import { getModalDataForCall } from "../Modals/AutoCallModalDataUtils";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
const showDevice = (hub) => !!hub.deviceuuid;

const StaffAdminFilterItems = {
  myAccount: "My Accounts",
  assignedToStaff: "Assigned to Staff",
  notAssignedToStaff: "Not Assigned to Staff",
  smartCameraOffline: "Smart Camera Offline",
  smartCameraOnline: "Smart Camera Online",
};
const FilterItems = {
  smartCameraOffline: "Smart Camera Offline",
  smartCameraOnline: "Smart Camera Online",
};
const getFilterMenu = (permission_level) => {
  const currentUserCheck =
    permission_level === UserPermissionLevel.staffAdmin
      ? StaffAdminFilterItems
      : FilterItems;
  return Object.values(currentUserCheck).map((item) => {
    return { key: item, label: item };
  });
};

const checkSearch = (hub, search) => {
  const hubname = getUsername(hub)?.toLowerCase();
  if (!!search && !hubname?.includes(search.toLowerCase())) {
    return false;
  }
  return true;
};
const checkFilter = (hub, filters) => {
  if (!filters?.length) return true;
  if (filters.includes(StaffAdminFilterItems.myAccount)) {
    if (hub.myaccount === true) {
      return true;
    }
  }
  if (filters.includes(StaffAdminFilterItems.assignedToStaff)) {
    if (hub.isstaffassigned === true) {
      return true;
    }
  }
  if (filters.includes(StaffAdminFilterItems.notAssignedToStaff)) {
    if (hub.isstaffassigned === false) {
      return true;
    }
  }
  if (
    filters.includes(
      StaffAdminFilterItems.smartCameraOnline || FilterItems.smartCameraOnline
    )
  ) {
    if (hub.deviceconnectstatus === "CONNECTED") {
      return true;
    }
  }
  if (
    filters.includes(
      StaffAdminFilterItems.smartCameraOffline || FilterItems.smartCameraOffline
    )
  ) {
    if (hub.deviceconnectstatus === "DISCONNECTED") {
      return true;
    }
  }
  return false;
};

const UserHubsComponent = ({
  userHubs = [],
  fetchingUserHubs,
  onActionClick,
}) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const currentUser = useSelector(getCurrentUser);
  const permissions = useSelector(getPermissions());

  const [hubList, setHubList] = useState(userHubs);

  /** search and filter variables */
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchApplied, setSearchApplied] = useState(null);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hubMessageData, setHubMessageData] = useState("");
  const [selectedHub, setSelectedHub] = useState("");
  const [autoAcceptCallHubs, setAutoAcceptCallHubs] = useState(new Map());
  const [allHubsProcessed,setAllHubsProcessed] = useState(false);
  const userApi = useSelector((state) => state.apis.userApi);
  const openSubMenu = true;
  const image = useMemo(
    () =>
      selectedHub?.profilePics &&
      selectedHub?.profilePics[PhotoResolution.R128],
    [selectedHub]
  );

  const FilterMenuItems = useMemo(
    () => getFilterMenu(currentUser.permission_level),
    [currentUser.permission_level]
  );

  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
 
  useEffect(() => {
    if (videoCallNotificationModalVisible) {
      setIsCallModalOpen(false);
    }
  }, [videoCallNotificationModalVisible]);

  const checkAutoCall = useMemo(
    () => checkRoleAccess(Access.videocall.allowAutoCall),
    [currentUser?.roles]
  );

  const allowCall = (hub) =>
    currentUser.useruuid !== hub.useruuid &&
    isActionAllowed(hub.rolename, KnownActionsList.call_contacts, permissions);

  const allowAutoCall = (hub) => 
    allowCall(hub) && allowAutoVideoCall(hub) && checkAutoCall;

  const handleModal = (hub) => {
    setIsModalOpen(true);
    setSelectedHub(hub);
  };

  const cancelModal = () => {
    setIsModalOpen(false);
  };

  const handleSendMessage = () => {
    setShowModal(true);
    setHubMessageData(modalDataForSendMessage);
  };

  const cancelSendMessage = () => {
    setShowModal(false);
  };

  const handleCallModal = (hub) => {
    setSelectedHub(hub);
    setIsCallModalOpen(true);
  };

  const cancelCallModal = () =>{
    setIsCallModalOpen(false);
  }

  useEffect(() => {
    if (userHubs.length > 0) {
      userHubs.forEach((hub,index) => {
        if (hub.useruuid) {
          fetchData(hub, index, userHubs.length);
        }
      });
    }
  }, [userHubs]);

  const fetchData = (hub, index, totalHubs) => {
    const errorCallback = (error) => {
      console.error("Error fetching user information:", error);
      console.error("Error creating videoCall Session ",error);
    };
    const successCallback = (data) => {
      console.info("UserInfo: fetch result", data);
      const user = data?.user;
      if (user) {
        const autoAcceptCall = user?.autoacceptcall && hub?.rolename !== 'Social';
        if (autoAcceptCall) {
          setAutoAcceptCallHubs((prev) => new Map(prev).set(hub.useruuid, autoAcceptCall));
        }
        if (index === totalHubs - 1) {
          setAllHubsProcessed(true);
        }
      }
    };
    try {
      if(!hub) return;
      userApi.getUserById(
        hub.useruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };
  const isDataFiltered = useMemo(
    () => searchApplied || appliedFilter.length !== 0,
    [searchApplied, appliedFilter]
  );

  const modalData = {
    image: image,
    devicename: getUsername(selectedHub),
    status: trans("OFFLINE"),
    cancel: cancelModal,
  };

  const modalDataForSendMessage = {
    title: <div className="messagetitle">{trans("DEVICEOFFLINE")}</div>,
    description: (
      <div className="messagedescription">{trans("MESSAGEISNOTSENT")}</div>
    ),
    action: "",
    icon: <HC200Icon status={"disconnected"} />,
    dismiss: cancelSendMessage,
  };

  useEffect(() => {
    handleSearchFilter();
  }, [userHubs]);

  const modalDataForCall = useMemo(() => 
    getModalDataForCall(selectedHub, cancelCallModal, dispatch,trans ), 
    [selectedHub, cancelCallModal,dispatch,trans]
  );
  
  /** search and filter handlers */
  const handleSearchToggle = () => {
    if (isSearchOn) {
      setSearchText(null);
      handleSearchClear();
    }
    setIsSearchOn((value) => !value);
  };
  const handleSearchClear = () => {
    setSearchApplied(false);
    setSearchText(null);
    handleSearchFilter("");
  };
  const handleFilterToggle = (e) => {
    // Stop the event propagation to prevent it from reaching the parent div
    e.stopPropagation();
    setIsFilterOn((value) => !value);
  };
  const handleFilterChange = (e) => {
    const { key } = e;
    let newFilter = appliedFilter;
    if (appliedFilter.includes(key)) {
      newFilter = newFilter.filter((f) => f !== key);
    } else {
      newFilter = [...newFilter, key];
    }
    setAppliedFilter(newFilter);
    handleSearchFilter(null, newFilter);
  };
  const handleSearchFilter = (search, filters) => {
    search = search ?? searchText;
    filters = filters ?? appliedFilter ?? [];
    const newList = userHubs.filter((hub) => {
      if (!checkSearch(hub, search)) {
        return false;
      }
      if (!checkFilter(hub, filters)) {
        return false;
      }
      return true;
    });
    setHubList(newList);
    if (!!searchText) {
      setSearchApplied(true);
    } else {
      setSearchApplied(false);
    }
  };

  /** render functions */
  const renderHeaderActions = (
    <div className="header-action-container">
      {!!appliedFilter.length && (
        <div className="applied-filter-container">
          {appliedFilter.map((item) => (
            <div key={"filter_" + item} className="applied-filter">
              <span className="text">{item}</span>
              <CloseIcon
                className="remove-icon"
                onClick={() => handleFilterChange({ key: item })}
              />
            </div>
          ))}
        </div>
      )}
      {isSearchOn && (
        <div className="search-container">
          <Input
            autoFocus
            className="search-input"
            value={searchText}
            onChange={(e) => setSearchText(e?.target?.value)}
            onPressEnter={() => handleSearchFilter()}
            suffix={
              searchApplied ? (
                <CloseOutlined
                  onClick={handleSearchClear}
                  title="Clear Search"
                />
              ) : (
                <span />
              )
            }
          />
          <span className="search-helper-text">
            {trans("PRESSENTERTOSEARCH")}
          </span>
        </div>
      )}
      <SearchIcon className="search-icon" onClick={handleSearchToggle} />
      <div className="filter-container">
        {isFilterOn ? (
          <CustomDropdown
            open={true}
            menuItems={FilterMenuItems}
            selectedKeys={appliedFilter}
            handleChange={handleFilterChange}
            onClick={handleFilterToggle}
          >
            <>
              <FilterIcon className="filter-icon" />
              <span className="text">{trans("FILTERS")}</span>
            </>
          </CustomDropdown>
        ) : (
          <FilterIcon className="filter-icon" onClick={handleFilterToggle} />
        )}
      </div>
    </div>
  );

  const renderUserType = (hub) =>
    isManage(hub) ? (
      <>
        <ManageIcon />
        <span className="type-text">{trans("MANAGE")}</span>
      </>
    ) : isCare(hub) ? (
      <>
        <CareIcon />
        <span className="type-text">{trans("CARE")}</span>
      </>
    ) : isSocial(hub) ? (
      <>
        <SocialIcon />
        <span className="type-text">{trans("SOCIAL")}</span>
      </>
    ) : (
      <div></div>
    );

  const renderUserHub = (hub) => {
    const contactsTooltipText = (hub?.numofcontacts <= 0 || hub?.numofcontacts === null) 
    ? "No Contacts" 
    : (hub?.numofadmincontacts <= 0 || hub?.numofadmincontacts === null) 
    ? "No Account Manager" 
    : "";
    const applyRedHover = contactsTooltipText === "No Contacts" || contactsTooltipText === "No Account Manager";
   
    return (
      <div key={hub.hubid} className="item-container hub-container">
        <div
          className="container-1">
          <div className="usertype-container">{renderUserType(hub)}</div>
          <div className="user-info-container"
           onClick={onActionClick(Actions.hubDetails, hub, openSubMenu)}>
            <div className="image-container">
              {hub.profilePic64 ? (
                <img src={hub.profilePic64} className=".border" alt="" />
              ) : (
                <ContactImage className="user-profile-pic" />
              )}
            </div>
            <div className="info-container">
              <div className="username">{getUsername(hub)}</div>
              <div className="relation">{hub.relationship}</div>
            </div>
          </div>
        </div>

        <div className="container-2">
          <Divider type="vertical" />
          <Tooltip title={contactsTooltipText}>
          <div
           className="association-count-container"
            onClick={onActionClick(Actions.listContacts, hub, true)}
          >
            <ContactIcon  className={`count-icon ${applyRedHover ? "red-icon" : ""}`} />
            <div className="count-container">
              <span className={`count-text ${applyRedHover ? "red-text" : ""}`}>{hub.numofcontacts ?? 0}</span>
            </div>
          </div>
          </Tooltip>
          <Divider type="vertical" />

          <div className="device-icon-container">
            {showDevice(hub) && (
              <NewHC200Icon
                status={
                  hub?.deviceconnectstatus?.toLowerCase() === "connected"
                    ? "connected"
                    : "disconnected"
                }
              />
            )}
          </div>

          <Divider type="vertical" />

          {isStaffActionAllowed(
            currentUser?.permission_level,
            KnownActionsList.manage_assign_staff,
            permissions
          ) && (
            <>
              <Tooltip title={trans("ASSIGNSTAFF")}>
                <div className="staff-add-icon-container">
                  <BadgeAddIcon
                    className="staff-add-icon"
                    onClick={onActionClick(Actions.assignStaff, hub)}
                  />
                </div>
              </Tooltip>

              <Divider type="vertical" />
            </>
          )}
          <div className="actions-container">
            {allowCall(hub) && allHubsProcessed  ? (
              <Tooltip title={trans("VIDEOCALL")}>
                <div
                  className="action"
                  onClick={() =>{
                    hub?.deviceconnectstatus === "CONNECTED"
                    ? autoAcceptCallHubs.get(hub.useruuid)
                      ? handleCallModal(hub)
                      : dispatch(startVideoCall({ callees: [hub], hub }))
                    : handleModal(hub) 
                  }}>
                  {autoAcceptCallHubs.get(hub.useruuid) ? (<AutoAnswerIcon />) : (<CallIcon />)}
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {isActionAllowed(
              hub.rolename,
              KnownActionsList.create_events,
              permissions
            ) ? (
              <Tooltip title={trans("SCHEDULEEVENT")}>
                <div
                  className="action"
                  onClick={onActionClick(Actions.addEvent, hub)}
                >
                  <AddNewScheduleIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {isActionAllowed(
              hub.rolename,
              KnownActionsList.call_contacts,
              permissions
            ) ? (
              <Tooltip title={trans("MESSAGE")}>
                <div
                  className="action"
                  onClick={
                    hub?.deviceconnectstatus?.toLowerCase() === "connected"
                      ? onActionClick(Actions.sendMessage, hub)
                      : handleSendMessage
                  }
                >
                  <NewSocialIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
            {isActionAllowed(
              hub.rolename,
              KnownActionsList.add_contacts,
              permissions
            ) ? (
              <Tooltip title={trans("INVITE")}>
                <div
                  className="action"
                  onClick={onActionClick(Actions.inviteContact, hub)}
                >
                  <NewContactIcon />
                </div>
              </Tooltip>
            ) : (
              <div className="empty-action" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showModal && <RebootResetModal data={hubMessageData} />}
      {isModalOpen && <UserHubModal data={modalData} />}
      {isCallModalOpen && <AutoCallModal data={modalDataForCall}/>}
      <MainPage hasSider={true}>
        <div className="userhubs-wrapper page-content-wrapper">
          <div className="page-header-container">
            <div className="page-header">
              <span>{currentUser.permission_level === "Staff Admin" ? trans("ACCOUNTS") : trans("MYACCOUNTS")}</span>         
              <SyncIcon
                className="clickable-item"
                onClick={onActionClick(null, null, false, true)}
              />
            </div>
            {renderHeaderActions}
          </div>
          <div className="item-count-container">
            <span className="item-count">
              {isDataFiltered ? `${hubList?.length ?? 0} found | ` : ""}
              {fetchingUserHubs ? (
                <LoadingOutlined />
              ) : (
                userHubs.length ?? 0
              )}{" "}
              {trans("TOTAL")}
            </span>
          </div>
          <div className="item-list-container hub-list-container">
            {fetchingUserHubs ? (
              <div className="item-container skeleton-container">
                <Skeleton active avatar paragraph={false} />
              </div>
            ) : !hubList.length ? (
              <Empty />
            ) : (
              hubList.map(renderUserHub)
            )}
          </div>
        </div>
      </MainPage>
    </>
  );
};

UserHubsComponent.propTypes = {
  userHubs: PropTypes.arrayOf(PropTypes.object),
  fetchingUserHubs: PropTypes.bool,
  onActionClick: PropTypes.func,
  openSubMenu: PropTypes.bool,
};

export default UserHubsComponent;
