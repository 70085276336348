/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Input, Spin, message } from "antd";
import { CheckIcon, CloseIcon, ContactImage } from "../Common/Icons";
import {
  Actions,
  PhotoResolution,
  RoleType,
  UserRoles,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  formatFullNameDisplay,
  isCare,
  isManage,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import useUserInfo from "../Hooks/useUserInfo";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import Roles from "../Roles/Roles";
import { useCallback } from "react";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";

const RequiredFields = {
  firstname: "First Name",
  lastname: "Last Name",
  email: "Email",
};

const DefaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  sourcerelationship: "",
  targetrelationship: "",
};

const formatData = (data, isEditForm, role) => {
  const userObj = { rolename: role };
  Object.keys(DefaultValues).forEach((key) => (userObj[key] = data[key]));
  if (isEditForm) {
    userObj.useruuid = data.useruuid;
  }
  return userObj;
};

const ContactForm = (props) => {
  const { parentBread, hubRecord, record, action, onActionClick, contacts } =
    props;

  const [role, setRole] = useState("");
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const currentUser = useSelector(getCurrentUser);

  const isEditForm = action === Actions.editContact;
  const header = (parentBread || []).concat({
    label: `${isEditForm ? trans("EDIT") : trans("ADD")} ${trans("CONTACT")}`,
  });

  const currentRole = useMemo(
    () =>
      isManage(record)
        ? UserRoles.manage
        : isCare(record)
        ? UserRoles.care
        : isSocial(record)
        ? UserRoles.social
        : null,
    [record]
  );
  const [isValid, setIsValid] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formData, setFormData] = useState(DefaultValues);
  const image = useMemo(
    () => hubRecord.profilePics && hubRecord.profilePics[PhotoResolution.R64],
    [hubRecord]
  );

  const [userInfo, fetchingUserInfo] = useUserInfo({
    useruuid: isEditForm && record?.useruuid,
  });
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    validate(false);
  }, [formData]);

  useEffect(() => {
    isEditForm &&
      setFormData({
        ...userInfo,
        sourcerelationship: record?.sourcerelationship,
        targetrelationship: record?.targetrelationship,
      });
  }, [userInfo, isEditForm, record]);

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    if (name === "email") {
      const emailPattern =
        /^(["a-zA-Z0-9.!#$%&()'*+/=?^_`{|}~\[\]-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const isValidEmail = emailPattern.test(value);
      setEmailError(isValidEmail ? "" : "Please enter a valid email address");
    }
    setFormData({ ...formData, [name]: value });
  };

  const validate = (showError = true) => {
    const values = formData;
    let valid = true;
    let erroredFields = [];
    for (let field of Object.keys(RequiredFields)) {
      if (!values.hasOwnProperty(field) || !!!values[field]) {
        erroredFields.push(RequiredFields[field]);
        valid = false;
      }
    }
    if (showError && !valid) {
      let error = "Please fill required fields: " + erroredFields.join(", ");
      message.error(error, 10);
    }
    setIsValid(valid && !emailError);
    return valid && !emailError;
  };

  const handleSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "updating" : "adding"} contact`;
      console.error(msg, error);
      message.error(msg);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Contact ${isEditForm ? "updated" : "added"} successfully!`;
      console.info(msg, data);
      message.success(msg);
      onActionClick(
        isEditForm ? Actions.editContact : null,
        isEditForm ? record : null,
        true
      )();
    };
    try {
      // Trim leading and trailing whitespace from formData
      const trimmedFormData = {
        ...formData,
        firstname: formData.firstname.trim(),
        lastname: formData.lastname.trim(),
        sourcerelationship: formData?.sourcerelationship?.trim(),
        targetrelationship: formData?.targetrelationship?.trim(),
      };
      console.debug("Submit:", formData);
      const valid = validate();
      if (!valid) return;
      if (!isEditForm) {
        const duplicateEmail = contacts.some(
          (contact) => contact?.email === formData?.email
        );
        if (duplicateEmail) {
          message.error(trans("EMAILALREADYINUSEMSG"));
          return;
        }
      }
      setProcessing(true);
      const obj = formatData(trimmedFormData, isEditForm, role);
      userApi.inviteContactsToUser(
        hubRecord?.useruuid,
        obj,
        apiCallback({
          translator: trans,
          successCallback,
          errorCallback,
          failCallback: errorCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleRoleSelection = useCallback((role) => {
    setRole(role);
  }, []);

  const renderFormItem = ({
    name,
    label,
    disabled,
    placeholder,
    className,
  }) => (
    <div className={`form-item form-item-verticle ${className ?? ""}`}>
      <label>{label}</label>
      <Input
        name={name}
        placeholder={placeholder}
        value={formData[name]}
        onChange={handleInputChange}
        disabled={disabled}
      />
      <div style={{ height: "0.5rem", marginTop: "0.3rem", color: "red" }}>
        {name === "email" && emailError && <span>{emailError}</span>}
      </div>
    </div>
  );

  const renderRole = ({ label, className }) => {
    const isCurrentUserEditing =
      currentUser && currentUser.useruuid === record?.useruuid;
    return (
      <div className={`form-item form-item-verticle ${className ?? ""}`}>
        <label>{label}</label>
        <Roles
          type={RoleType.user}
          onHandleSelection={handleRoleSelection}
          currentRole={currentRole}
          disabled={isCurrentUserEditing}
        />
      </div>
    );
  };

  return (
    <div className="contact-form-container page-content-wrapper">
      <Spin
        spinning={processing || fetchingUserInfo}
        wrapperClassName="add-contact-form custom-form"
      >
        <div className="form-group-1">
          {renderFormItem({
            name: "firstname",
            label: trans("FIRSTNAME"),
            placeholder: "",
            disabled: isEditForm,
          })}
          {renderFormItem({
            name: "lastname",
            label: trans("LASTNAME"),
            placeholder: "",
            disabled: isEditForm,
          })}
          {renderFormItem({
            name: "email",
            label: trans("EMAIL"),
            placeholder: "",
            disabled: isEditForm,
          })}
        </div>
        <div className="form-group-2">
          <div className="flex-column" style={{ flex: "1" }}>
            <div className="hub-info-container">
              <label className="label">{trans("ACCOUNTPRINCIPAL")}</label>
              <div className="user-info-container">
                <div className="flex-align-center overflow-hidden">
                  {image ? (
                    <img
                      src={image}
                      className="user-profile-pic user-profile-image"
                      alt=""
                    />
                  ) : (
                    <ContactImage className="user-profile-pic" />
                  )}
                  <label className="user-name">
                    {formatFullNameDisplay(
                      hubRecord?.firstname,
                      hubRecord?.lastname
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="form-card">
              <span className="header-text">
                {isEditForm ? trans("EDIT") : trans("ADD")} {trans("CONTACT")}
              </span>
              <div className="flex-column">
                {renderFormItem({
                  name: "targetrelationship",
                  label: (
                    <span>
                      {trans("CONTACTSACCOUNTPRINCIPALRELATIONSHIP")}
                      <span
                        style={{
                          color: "#868585",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginLeft: 10,
                        }}
                      >
                        ({trans("OPTIONAL")})
                      </span>
                    </span>
                  ),
                  // placeholder: "Friend, sister, brother, neighbor, etc.",
                })}
                {renderFormItem({
                  name: "sourcerelationship",
                  label: (
                    <span>
                      {trans("ACCOUNTPRINCIPALCONTACTRELATIONSHIP")}
                      <span
                        style={{
                          color: "#868585",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginLeft: 10,
                        }}
                      >
                        ({trans("OPTIONAL")})
                      </span>
                    </span>
                  ),
                  // placeholder: "Friend, sister, brother, neighbor, etc.",
                  className: "top-margin",
                })}
                {renderRole({
                  label: trans("PERSONACCOUNTPRINCIPALROLE"),
                  className: "top-margin",
                })}
              </div>
            </div>
            <div className="flex-row">
              <CustomButton
                label={trans("CANCEL_U")}
                style={{ marginRight: "13px" }}
                icon={<CloseIcon className="cancel-icon" />}
                onClick={onActionClick(null, null)}
              />
              <CustomButton
                type={ButtonTypes.primary}
                label={trans("SUBMIT_U")}
                icon={<CheckIcon className="submit-icon" />}
                onClick={handleSubmit}
                disabled={!isValid}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

ContactForm.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  hubRecord: PropTypes.object,
  record: PropTypes.object,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
};

export default ContactForm;
