/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider, Tooltip } from "antd";
import {
  Actions,
  PhotoResolution,
  UserLocale,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  getProfilePicUrl,
  getUsername,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import MainPage from "../../MainPage";
import {
  ContactImage,
  NewHC200Icon,
  SyncIcon,
  NewGearIcon,
  NewCallIcon,
  NewMessageIcon,
  NewAutoCallIcon,
} from "../Common/Icons";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { useDispatch, useSelector } from "react-redux";
import Schedule from "../Schedule/Schedule";
import useUserHubs from "../Hooks/useUserHubs";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import {
  Access,
  checkRoleAccess,
  KnownActionsList,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useDevicesList from "../Hooks/useDevicesList";
import UserHubModal from "../UserHubs/UserHubModal";
import RebootResetModal from "../Devices/RebootResetModal";
import AutoCallModal from  "../Modals/AutoCallModal";
import { getModalDataForCall } from "../Modals/AutoCallModalDataUtils";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { allowAutoVideoCall } from "../../../@commscopecloud/humaui/Utilities/CommonUtilities";
import { Contacts } from "../Contacts/Contacts";
import CallActivity from "../CallActivity/CallActivity";
import EditUserProfile from "../UserProfile/EditUserProfile";
import MotionActivity from "../MotionActivity/MotionActivity";
import Environmental from "../Environmental/Environmental";
import AssignStaff from "../Staff/AssignStaff";
import ContactForm from "../Contacts/ContactForm";
import SendMessage from "../SendMessage/SendMessage";
import useContacts from "../Hooks/useContacts";
import useUserInfo from "../Hooks/useUserInfo";
import moment from "moment-timezone";

const HubDetailsComponent = ({
  header,
  hubRecord,
  openSubMenu,
  onActionClick,
  rolename,
  tabSwitch,
  activeSubMenuContent,
  userHubCall,
  sendMsgDisplay = false,
  navigationKey = false,
  userHubsData,
  offset,
  limit,
  showContactingStaff
}) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const location = useLocation();
  const path = location.pathname.split("/")[1]?.toLowerCase();
  const useruuid = useMemo(() => hubRecord.useruuid, [hubRecord]);
  const userData = useSelector((user) => user.data.userInfo);
  const updatedUserInfo = userData[useruuid];
  const resourcesMap = useSelector(getProfilePics());
  const currentUser = useSelector(getCurrentUser);
  const { providerid } = useMemo(() => currentUser, [currentUser]);
  const [userHubs] = useUserHubs({ useruuid });
  const deviceID = useMemo(() => {
    const hub = userHubs?.find((hub) => hub.useruuid === useruuid);
    return hub?.hubname;
  }, [userHubs]);
  const [userInfo, , fetchUserInfo] = useUserInfo({
    useruuid: hubRecord?.useruuid,
  });

  const hubUseruuid = hubRecord.useruuid;
  const [contacts, fetchingContacts, fetchContacts] = useContacts({
    useruuid: hubUseruuid,
  });

  const [fetchingDevicesData, fetchDevices, devicesData] = useDevicesList({
    providerid,
  });
  const [deviceStatus, setDeviceStatus] = useState("");
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [statusTitle, setStatusTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [hubMessageData, setHubMessageData] = useState("");
  const queryParams = { filterkey: "useruuid", filtervalue: useruuid };
  const [selectedHub, setSelectedHub] = useState("");
  const [activeTab, setActiveTab] = useState(
    tabSwitch ? tabSwitch : "schedule"
  ); // State to manage the active tab

  const handleTabChange = (key) => {
    setActiveTab(null);
    setTimeout(() => {
      setActiveTab(key);
      path === "devices"
        ? onActionClick(Actions.listHub, hubRecord, offset, limit)()
        : onActionClick(Actions.hubDetails, hubRecord, true, false)();
    }, 50);
  };

  const image = useMemo(
    () =>
      selectedHub?.profilePics &&
      selectedHub?.profilePics[PhotoResolution.R128],
    [selectedHub]
  );

  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
 
  useEffect(() => {
    if (videoCallNotificationModalVisible) {
      setIsCallModalOpen(false);
    }
  }, [videoCallNotificationModalVisible]);

  const handleModal = (hubRecord) => {
    setShowModal(true);
    setSelectedHub(hubRecord);
  };

  const cancelModal = () => {
    setShowModal(false);
  };

  const handleSendMessage = () => {
    setIsModalOpen(true);
    setHubMessageData(modalDataForSendMessage);
  };

  const cancelSendMessage = () => {
    setIsModalOpen(false);
  };

  const handleCallModal =() =>{
    setIsCallModalOpen(true);
  };

  const cancelCallModal = () =>{
    setIsCallModalOpen(false);
  };

  const filteredUserHubsData = useMemo(() => {
    if (userHubsData && navigationKey) {
      return userHubsData.filter((hub) => hub.useruuid === hubRecord.useruuid);
    }
  }, [hubRecord.useruuid, userHubsData]);

  const modalData = {
    image: image,
    devicename: getUsername(selectedHub),
    status: trans("OFFLINE"),
    cancel: cancelModal,
  };

  const modalDataForSendMessage = {
    title: <div className="messagetitle">{trans("DEVICEOFFLINE")}</div>,
    description: (
      <div className="messagedescription">{trans("MESSAGEISNOTSENT")}</div>
    ),
    action: "",
    icon: <NewHC200Icon status={"disconnected"} />,
    dismiss: cancelSendMessage,
  };

  useEffect(() => {
    if (devicesData?.length > 0) {
      const connectionStatus = devicesData[0].deviceconnectstatus.toLowerCase();
      const provisioningStatus =
        devicesData[0].provisioningstatus.toLowerCase();
      let title = "";
      if (connectionStatus === "connected") title = "Online | ";
      else title = "Offline | ";
      if (provisioningStatus === "provisioned") title = title + "Assigned";
      else title = title + "UnAssigned";
      setDeviceStatus(connectionStatus);
      // setStatusTitle(title);
      setStatusTitle("Smart Camera");
      setDeviceLocation(devicesData[0]?.location);
    }
  }, [devicesData]);

  useEffect(() => {
    fetchDevices(queryParams);
  }, []);

  const permissions = useSelector(getPermissions());
  const allowCallContact = useMemo(
    () =>
      isActionAllowed(
        navigationKey
          ? filteredUserHubsData &&
              filteredUserHubsData.length > 0 &&
              filteredUserHubsData[0].rolename
          : hubRecord?.rolename,
        KnownActionsList.call_contacts,
        permissions
      ),
    [hubRecord?.rolename, permissions, filteredUserHubsData]
  );
  const allowAutoCall = userInfo?.autoacceptcall && hubRecord?.rolename !== 'Social';
  // useMemo(
  //   () =>
  //     allowAutoVideoCall(
  //       navigationKey
  //         ? filteredUserHubsData &&
  //             filteredUserHubsData.length > 0 &&
  //             filteredUserHubsData[0]
  //         : hubRecord
  //     ) && checkRoleAccess(Access.videocall.allowAutoCall),
  //   [hubRecord, currentUser?.roles, filteredUserHubsData]
  // );
  const allowManageContacts = useMemo(
    () =>
      isActionAllowed(
        navigationKey
          ? filteredUserHubsData &&
              filteredUserHubsData.length > 0 &&
              filteredUserHubsData[0].rolename
          : hubRecord?.rolename,
        KnownActionsList.manage_contacts,
        permissions
      ),
    [hubRecord?.rolename, permissions, filteredUserHubsData]
  );

  const allowStaffAndStaffAdmin = useMemo(
    () =>
      (currentUser?.permission_level === "Staff" &&
        hubRecord?.rolename === "Manage") ||
      currentUser?.permission_level === "Staff Admin",
    [currentUser, hubRecord]
  );

  const hubRecordProfilePic = useMemo(
    () => getProfilePicUrl(useruuid, resourcesMap, PhotoResolution.R256),
    [hubRecord, resourcesMap]
  );

  const renderName = (
    <Tooltip title={getUsername(updatedUserInfo)}>
      {getUsername(updatedUserInfo)}
    </Tooltip>
  );

  const get_utc_offset = (timeZone) => {
    timeZone = timeZone.split(" ")[0];
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName")
      .value.replace("GMT", "");
  };

  const getTimeZoneFullForm = useMemo(() => {
    if (!updatedUserInfo?.timezone) return;
    const timeZone = updatedUserInfo.timezone.split(" ")[0];
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timeZone,
      timeZoneName: "long",
    });
    const parts = formatter.formatToParts(new Date());
    const timeZoneName = parts.find(
      (part) => part.type === "timeZoneName"
    ).value;
    return timeZoneName;
  }, [updatedUserInfo?.timezone]);

  const modalDataForCall = useMemo(() => 
    getModalDataForCall(hubRecord, cancelCallModal, dispatch,trans ), 
    [hubRecord, cancelCallModal,dispatch,trans]
  );

  const renderHeader = (
    <header className="hub-header-container">
      <div className="hub-info-container">
        <div className="hub-image-container">
          {hubRecordProfilePic ? (
            <img
              src={hubRecordProfilePic}
              className="user-profile-pic"
              alt=""
            />
          ) : (
            <ContactImage className="user-profile-pic" />
          )}
        </div>
        <div className="hub-info">
          <label className="user-name">{renderName}</label>
          <div className="user-role-container">
            <label className="user-role-locale">
              {UserLocale[updatedUserInfo?.locale]}
            </label>
            <Divider
              className="divider"
              type="vertical"
              style={{
                border: "1px solid #C9C9C9",
                margin: "1px 8px",
                height: "18px",
              }}
            />
            <label className="user-role-timezone">{getTimeZoneFullForm}</label>
          </div>
          <div
            className="edit-profile-container"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            {(allowStaffAndStaffAdmin || allowManageContacts) && (
              <NewGearIcon
                className="hub-edit-icon"
                onClick={() => {
                  setActiveTab("editProfile");
                }}
                style={{ cursor: "pointer", fill: "#D9ECFA" }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="hub-action-device-container">
        <div className="header-action-buttons">
          {allowCallContact && !!deviceStatus && allowAutoCall !== undefined &&(
            <>
              <Tooltip title={trans("VIDEOCALL")}>
                <div
                  className="action"
                  disabled={fetchingDevicesData}
                  onClick={() => {
                    deviceStatus === "connected"
                      ? allowAutoCall
                        ? handleCallModal()
                        : dispatch(startVideoCall({ callees: [hubRecord], hubRecord }))
                      : handleModal(hubRecord)
                    }}>
                  {allowAutoCall ? (<NewAutoCallIcon />) : ( <NewCallIcon />)}
                </div>
              </Tooltip>
              {sendMsgDisplay && (
                <NewMessageIcon
                  className="action"
                  onClick={
                    deviceStatus === "connected"
                      ? () => {
                          setActiveTab("sendMessage");
                        }
                      : handleSendMessage
                  }
                />
              )}
            </>
          )}
        </div>
        <div
          className="divider"
          type="vertical"
          style={{
            border: "1px solid #C9C9C9",
            margin: "1px 8px",
            height: "80px",
          }}
        ></div>
        <div className="header-device-info-container">
          <div className="header-device-description-container">
            <p className="title-1">
              {deviceLocation &&
                deviceLocation
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}
            </p>
            <p className="title-2">{statusTitle}</p>
            <p className="title-3">{deviceID ? (deviceID.length <= 12 ? deviceID : deviceID.substring(0,12)) : ''}</p>
          </div>
          <NewHC200Icon status={deviceStatus} className="icon" />
          <Tooltip title={trans("REFRESH")}>
            <div className="refreshicon">
              <SyncIcon
                onClick={() => fetchDevices(queryParams)}
                style={{ cursor: "pointer", padding: "2px" }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </header>
  );

  return (
    <>
      {isModalOpen && <RebootResetModal data={hubMessageData} />}
      {showModal && <UserHubModal data={modalData} />}
      {isCallModalOpen && <AutoCallModal data={modalDataForCall}/>}
      <MainPage
        header={header}
        hasSider={true}
        activeKey={activeTab}
        openSubMenu={openSubMenu}
        onChange={handleTabChange}
        hubUserRole={
          navigationKey
            ? filteredUserHubsData &&
              filteredUserHubsData.length > 0 &&
              filteredUserHubsData[0].rolename
            : hubRecord?.rolename
        }
        onActionClick={onActionClick}
        navigationKey={navigationKey}
        offset={offset}
        limit={limit}
      >
        <div className="hub-details-wrapper page-content-wrapper">
          {renderHeader}
          <div className="hub-content-container">
            <div className="hubnewui-content-container">
              {activeTab === "schedule" && (
                <div className="hubnewui-schedule-container">
                  <Schedule
                    onlyContent={true}
                    action={
                      activeSubMenuContent === "addEvent"
                        ? Actions.addEvent
                        : null
                    }
                    hubRecord={hubRecord}
                    onActionClick={onActionClick}
                    rolename={rolename}
                    handleTabChange={handleTabChange}
                    openSubMenu={openSubMenu}
                    activeKey={activeTab}
                    userHubCall={userHubCall}
                  />
                </div>
              )}
              {activeTab === "contacts" && (
                <div className="hubnewui-contacts-container">
                  <Contacts
                    parentBread={header}
                    hubRecord={
                      navigationKey
                        ? filteredUserHubsData &&
                          filteredUserHubsData.length > 0 &&
                          filteredUserHubsData[0]
                        : hubRecord
                    }
                    onActionClick={onActionClick}
                    openSubMenu={openSubMenu}
                    handleTabChange={handleTabChange}
                    activeKey={activeTab}
                    userHubCall={userHubCall}
                  />
                </div>
              )}
              {activeTab === "callActivity" && (
                <div className="hubnewui-callactivity-container">
                  <CallActivity
                    parentBread={header}
                    onActionClick={onActionClick}
                    hubRecord={hubRecord}
                    timezone={updatedUserInfo?.timezone}
                  />
                </div>
              )}
              {activeTab === "motionactivity" && (
                <div className="hubnewui-motionactivity-container">
                  <MotionActivity
                    parentBread={header}
                    // onActionClick={onActionClick(
                    //   Actions.motionActivity,
                    //   hubRecord
                    // )}
                    hubRecord={hubRecord}
                    timezone={updatedUserInfo?.timezone}
                    deviceStatus={deviceStatus}
                  />
                </div>
              )}
              {activeTab === "environmental" && (
                <div className="hubnewui-environmental-container">
                  <Environmental
                    // parentBread={header}
                    // onActionClick={onActionClick(
                    //   Actions.motionActivity,
                    //   hubRecord
                    // )}
                    hubRecord={hubRecord}
                    timezone={updatedUserInfo?.timezone}
                  />
                </div>
              )}
              {activeTab === "editProfile" && (
                <div className="hubnewui-callactivity-container">
                  <EditUserProfile
                    parentBread={header}
                    hubRecord={hubRecord}
                    onActionClick={onActionClick}
                    openSubMenu={openSubMenu}
                    handleTabChange={handleTabChange}
                    userHubCall={userHubCall}
                    activeKey={activeTab}
                    showContactingStaff={showContactingStaff}
                    filteredUserHubsData={filteredUserHubsData}
                  />
                </div>
              )}
              {activeTab === "inviteContact" && (
                <div className="hubnewui-contacts-container">
                  <ContactForm
                    parentBread={header}
                    hubRecord={hubRecord}
                    onActionClick={onActionClick}
                    contacts={contacts}
                  />
                </div>
              )}
              {activeTab === "sendMessage" && (
                <div className="hubnewui-callactivity-container">
                  <SendMessage
                    record={hubRecord}
                    parentBread={header}
                    onActionClick={onActionClick}
                    openSubMenu={openSubMenu}
                    handleTabChange={handleTabChange}
                    userHubCall={userHubCall}
                    activeKey={activeTab}
                  />
                </div>
              )}
              {activeTab === "assignStaff" && (
                <div className="hubnewui-callactivity-container">
                  <AssignStaff
                    record={hubRecord}
                    onActionClick={onActionClick}
                    openSubMenu={openSubMenu}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </MainPage>
    </>
  );
};

HubDetailsComponent.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  hubRecord: PropTypes.object,
  onActionClick: PropTypes.func,
};

export default HubDetailsComponent;
