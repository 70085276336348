import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ProgressBar from "../Common/ProgressBar";
import { CareIcon, CheckIcon, CloseIcon, ManageIcon, SocialIcon } from "../Common/Icons";
import { HSButtonType } from "../Common/HSButton";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { RoleType } from "@commscopemycloud/humaui/Utilities/Constants";
import CustomButton from "../Common/CustomButton";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const Roles = ({ type, onHandleSelection, currentRole, disabled = false}) => {
  const [currentSelection, setCurrentSelection] = useState(0);
  const trans = useSelector(translator);

  const initialState = useSelector(getPermissions());
  const state = useMemo(() => {
    const {
      social,
      manage,
      care,
      staff,
      "staff admin" : staff_admin,
    } = initialState;
    const currState = [];
    if (type === RoleType.user) {
      currState.push({
        buttonType: HSButtonType.social,
        data: social,
        roledescription: "Communication only",
      });
      currState.push({
        buttonType: HSButtonType.care,
        data: care,
        roledescription: "Visibility into activity",
      });
      currState.push({
        buttonType: HSButtonType.manage,
        data: manage,
        roledescription: "Access to everything",
      });
    } else {
      currState.push({ buttonType: HSButtonType.staff, data: staff });
      currState.push({
        buttonType: HSButtonType.staff_admin,
        data: staff_admin,
      });
    }
    for (const [i, val] of currState.entries()) {
      if (val.data?.rolename === currentRole) {
        setCurrentSelection(i);
        break;
      }
    }
    return currState;
  }, [initialState, type, currentRole]);

  const progressMultiplier = useMemo(() => 100 / (state?.length ?? 0), [state]);

  const getButtonIcon = (buttonType) => {
    switch (buttonType) {
      case "MANAGE":
        return <ManageIcon />;
      case "CARE":
        return <CareIcon />;
      case "SOCIAL":
        return <SocialIcon />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (typeof onHandleSelection !== "undefined") {
      onHandleSelection(state[currentSelection]?.data?.rolename);
    }
  }, [currentSelection, onHandleSelection, state]);

  const renderButtonLabel = (role, index) => {
    const isButtonSelected = index === currentSelection;
    const isDescHighlighted = index <= currentSelection;
    const icon = getButtonIcon(role?.buttonType);

    return (
      <div className="column">
        <CustomButton
          type={isButtonSelected && "primary"}
          className={`action ${(isButtonSelected && !disabled) ? "button-selected" : ""}`}
          label={trans(role?.buttonType)}
          icon={ icon } 
          onClick={() => !disabled && setCurrentSelection(index)}
          disabled={disabled}
        />
        <p className={"desc " + (isDescHighlighted ? "desc-selected" : "")}>
          {role?.roledescription}
        </p>
      </div>
    );
  };

  const renderPermissions = (role, index) => {
    const isAllowed = index <= currentSelection;
    return (
      <div className="column">
        {role?.data?.permissions.map((data) => {
          return (
            <div className="permissions-item">
              <div className="icon">
                {isAllowed && <CheckIcon className="tick" />}
                {!isAllowed && <CloseIcon />}
              </div>
              <p>{data.privilegedisplayname}</p>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="role-container">
      <div className="row">
        {state.map((role, index) => {
          return renderButtonLabel(role, index);
        })}
      </div>
      <ProgressBar percent={(currentSelection + 1) * progressMultiplier} />
      <div className="row">
        {state.map((role, index) => {
          return renderPermissions(role, index);
        })}
      </div>
    </div>
  );
};

Roles.propTypes = {
  type: PropTypes.string,
  currentRole: PropTypes.string,
  onHandleSelection: PropTypes.func,
};

export default React.memo(Roles);
