/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { Layout, Tooltip } from "antd";
import {
  ContentShare,
  LocalVideo,
  useContentShareControls,
  useContentShareState,
  useLocalVideo,
  useRemoteVideoTileState,
  useRosterState,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import VideoCallSettings from "./VideoCallSetings";
import InviteParticipants from "./InviteParticipants";
import UserVideoTile from "./UserVideoTile";
import CustomSwitch from "../Common/CustomSwitch";
import {
  decodeExternalUserId,
  getName,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  CallEndStatus,
  CallFailStatus,
  CallWaitStatus,
  DateTimeFormat,
  PhotoResolution,
  UnknownUsername,
} from "@commscopemycloud/humaui/Utilities/Constants";
import {
  CallIcon,
  CloseIcon,
  ContactIcon1,
  ContactImage,
  EndCallIcon,
  MicOffIcon,
  MicOnIcon,
  ScheduleIcon,
  ScreenShareOnIcon,
  SettingsIcon,
  VideoOffIcon,
  VideoOnIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "../Common/Icons";
import CustomButton, { ButtonTypes } from "../Common/CustomButton";
import { getMeetingInfo } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const CallOptions = {
  invite: "Participants",
  settings: "Settings",
};

const getHeightParam = (tiles) => {
  if (!tiles || tiles === 1 || tiles === 2) return [100, 0];
  if (tiles === 3 || tiles === 4 || tiles === 5 || tiles === 6) {
    return [100 / 2, 10 / 2];
  } else {
    return [100 / 3, (10 + 10) / 3];
  }
};
const getWidthParam = (tiles) => {
  if (!tiles || tiles === 1) return [100, 0];
  if (tiles === 2 || tiles === 3 || tiles === 4) {
    return [100 / 2, 10 / 2];
  } else {
    return [100 / 3, (10 + 10) / 3];
  }
};

const getDisplayName = (user, name) => {
  let username = getName(user);
  return username !== UnknownUsername ? username : name;
};

const formatDuration = (start, end) => {
  let hours = end.diff(start, "hours");
  start.add(hours, "hours");
  let minutes = end.diff(start, "minutes");
  start.add(minutes, "minutes");
  let seconds = end.diff(start, "seconds");
  // if (minutes < 10) minutes = "0" + minutes;
  if (hours > 0) {
    // if (hours < 10) hours = "0" + hours;
    return hours + " hr " + minutes + " min";
  }
  return minutes < 1 ? seconds + " sec" : minutes + " min ";
};

const VideoCallComponent = ({
  isSetupDone,
  callStatus,
  onEndCall,
  meetingInfo,
  participantsMuteStatus,
}) => {
  const trans = useSelector(translator);
  const { startTime, endTime } = meetingInfo ?? {};
  const currentUser = useSelector(getCurrentUser);
  const eventInfo = useSelector(getMeetingInfo)?.eventInfo;
  const allUserInfo = useSelector((state) => state.data.userInfo);

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();
  const { toggleContentShare } = useContentShareControls();

  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  const isPresenting = !!sharingAttendeeId;
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const [localChimeUser, attendeeMap] = useMemo(() => {
    let local = {};
    const others = (attendees ?? []).reduce((result, a) => {
      const { useruuid, name } = decodeExternalUserId(a.externalUserId);
      if (useruuid === currentUser.useruuid) {
        local = { ...a, name, useruuid };
      } else {
        result[useruuid] = { ...a, name, useruuid };
      }
      return result;
    }, {});
    return [local, others];
  }, [attendees]);

  const callWaited = useMemo(
    () => CallWaitStatus.includes(callStatus),
    [callStatus]
  );
  const callFailed = useMemo(
    () => CallFailStatus.includes(callStatus),
    [callStatus]
  );
  const callEnded = useMemo(
    () => CallEndStatus.includes(callStatus),
    [callStatus]
  );

  /* call-options related variables */
  const [selectedOption, setSelectedOption] = useState(null);

  /* call information related */
  const [duration, setDuration] = useState(null);

  /* invites tab related variables */
  const invites = useSelector((state) => state.videoCall.invites);

  /* settings tab related variables */
  const [showSelfView, setShowSelfView] = useState(true);

  const gridSize = useMemo(
    () => (callWaited ? 1 : attendees?.length - 1),
    [attendees.length, callWaited]
  );
  const videoTileSize = useMemo(() => {
    let [h, ho] = getHeightParam(gridSize);
    let [w, wo] = getWidthParam(gridSize);
    let height = `calc(${h}% - ${ho}px)`;
    let width = `calc(${w}% - ${wo}px)`;
    return { height, width };
  }, [gridSize]);

  const calleeName = useMemo(() => {
    let name = "";
    if (!Object.keys(attendeeMap ?? {}).length) {
      let user = invites.filter((u) => u.useruuid !== currentUser.useruuid);
      user = (user || [])[0] || {};
      user = allUserInfo[user?.useruuid];
      name = getDisplayName(user);
    } else {
      let attendeeList = Object.values(attendeeMap);
      if (attendeeList.length) {
        let user = attendeeList[0];
        let userInfo = allUserInfo[user.useruuid];
        name = getDisplayName(userInfo, user.name);
        if (attendeeList.length > 1) {
          name += " +" + (attendeeList.length - 1);
        }
      }
    }
    return name;
  }, [callWaited, invites, attendeeMap]);

  const callFormatDuration = (start, end) => {
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const components = [];
    if (hours > 0) {
      components.push(`${hours} hr`);
    }
    if (minutes > 0 || (hours === 0 && seconds === 0)) {
      components.push(`${minutes} min`);
    }
    if (seconds > 0 && hours === 0 && minutes === 0) {
      components.push(`${seconds} sec`);
    }
    const formattedStartTime = moment(start).format("h:mma");
    const formattedEndTime = moment(end).format("h:mma");
    return `${trans("CALLWAS")} ${components.join(", ")} ${trans(
      "FROM"
    )} ${formattedStartTime}-${formattedEndTime}`;
  };

  /* To enable video by default  */
  useEffect(() => {
    if (isSetupDone && !isVideoEnabled) {
      toggleVideo();
    }
  }, [isSetupDone]);

  /* Updates duration of call */
  useEffect(() => {
    const interval = startTime
      ? setInterval(() => {
          const stime = moment(startTime, DateTimeFormat);
          const dur = formatDuration(stime, moment());
          setDuration(dur);
        }, 1000)
      : null;

    return () => {
      interval && clearInterval(interval);
    };
  }, [startTime]);

  const optionsClassname = (option) => {
    return selectedOption === option ? "selected" : "";
  };

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const renderTiles = () => {
    return Object.values(attendeeMap)
      .slice(0, 9) /* max 9 other users */
      .map((user) => {
        const { chimeAttendeeId, useruuid, name } = user;
        const isMuted = participantsMuteStatus[chimeAttendeeId];
        const tileid = attendeeIdToTileId[chimeAttendeeId];
        const userInfo = allUserInfo[useruuid];
        let username = getDisplayName(userInfo, name);
        return tileid ? (
          <div style={videoTileSize}>
            <UserVideoTile
              tileId={tileid}
              name={username}
              isMuted={isMuted}
              videoProps={{ className: "remote-video" }}
            />
          </div>
        ) : (
          useruuid !== currentUser.useruuid && (
            <div
              style={videoTileSize}
              className="video-off-container flex-center"
            >
              <UserVideoTile
                name={username}
                user={userInfo}
                isMuted={isMuted}
              />
            </div>
          )
        );
      });
  };

  const renderSelfTile = () => {
    return (
      showSelfView && (
        <>
          {isVideoEnabled ? (
            <>
              <LocalVideo className="local-video" />
              <VisibilityOffIcon className="visibility-icon" />
            </>
          ) : (
            <div className="video-off-container flex-center">
              <UserVideoTile
                user={allUserInfo[currentUser.useruuid]}
                scale={1.4}
              />
              <VisibilityIcon className="visibility-icon" />
            </div>
          )}
        </>
      )
    );
  };

  const renderVideoTiles = () => {
    let otherUser = null;
    let name = null;
    if (callWaited) {
      otherUser = invites.filter((u) => u.useruuid !== currentUser.useruuid);
      otherUser = (otherUser || [])[0] || {};
      otherUser = allUserInfo[otherUser?.useruuid];
      name = getName(otherUser);
    }
    return callWaited ? (
      <UserVideoTile status={callStatus} name={name} user={otherUser} />
    ) : !isPresenting || isLocalUserSharing ? (
      <div className="video-grid">{renderTiles()}</div>
    ) : (
      <div className="screen-share-container">
        <ContentShare className="screen-share-tile" nameplate="Content share" />
      </div>
    );
  };

  const renderVideoCallInfo = () => {
    const callDesc = calleeName ? trans("CALLWITH") + " " + calleeName : null;
    return (
      <div className="call-info-container">
        <div className="call-info">
          {eventInfo ? (
            <>
              <ScheduleIcon className="schedule-icon" />
              <Tooltip title={eventInfo?.event_name}>
                <label className="event-name">{eventInfo?.event_name}</label>
              </Tooltip>
            </>
          ) : (
            <>
              <CallIcon className="call-icon" />
              {callDesc && (
                <Tooltip title={callDesc}>
                  <label className="callee-info">{callDesc}</label>
                </Tooltip>
              )}
            </>
          )}
        </div>
        {duration && (
          <div className="timer-display">
            <label className="label">{trans("DURATION")}</label>
            <label className="value duration-value">{duration}</label>
          </div>
        )}
      </div>
    );
  };

  const renderVideoCallActions = () => (
    <div className="call-actions-container">
      <div className="container-1">
        <div className="call-option-container">
          <CustomSwitch
            className="primary"
            checked={isVideoEnabled}
            onChange={toggleVideo}
            disabled={!isSetupDone}
            checkedIcon={<VideoOnIcon className="video-icon" />}
            uncheckedIcon={<VideoOffIcon className="video-off-icon" />}
          />
        </div>
        <div className="call-option-container">
          <CustomSwitch
            className="primary"
            checked={!muted}
            onChange={toggleMute}
            disabled={!isSetupDone}
            checkedIcon={<MicOnIcon className="mic-icon" />}
            uncheckedIcon={<MicOffIcon className="mic-off-icon" />}
          />
        </div>
        <CustomButton
          className="end-button"
          type={ButtonTypes.warning}
          label={callFailed ? trans("videocall_ok") : trans("ENDCALL_U")}
          icon={!callFailed && <EndCallIcon />}
          onClick={() =>
            callFailed
              ? onEndCall({ closeModal: true })
              : onEndCall({ leaveCall: true, endCall: true })
          }
        />
      </div>
      <div className="container-2">
        <div
          className={`count-container ${optionsClassname(CallOptions.invite)}`}
          onClick={() => handleOptionClick(CallOptions.invite)}
        >
          <ContactIcon1
            className={`contact-icon ${optionsClassname(CallOptions.invite)}`}
          />
          <label className="count">{attendees?.length ?? 0}</label>
        </div>
        <div className="screen-share-icon-container">
          <ScreenShareOnIcon
            className={`screen-icon ${isLocalUserSharing ? "selected" : ""}`}
            disabled={!isSetupDone}
            onClick={toggleContentShare}
          />
          {isLocalUserSharing && (
            <label className="sub-label">{trans("SHARING")}</label>
          )}
        </div>
        <SettingsIcon
          className={`settings-icon ${optionsClassname(CallOptions.settings)}`}
          disabled={!isSetupDone}
          onClick={() => handleOptionClick(CallOptions.settings)}
        />
      </div>
    </div>
  );

  const renderOptionsSider = () => (
    <div className="call-options-sider">
      <div className="option-title-container">
        <label className="option-title">{selectedOption}</label>
        <CloseIcon
          className="close-icon clickable-item"
          onClick={() => setSelectedOption(null)}
        />
      </div>
      {selectedOption === CallOptions.invite ? (
        <InviteParticipants />
      ) : (
        <VideoCallSettings
          isSetupDone={isSetupDone}
          localChimeUser={localChimeUser}
          showSelfView={showSelfView}
          setShowSelfView={setShowSelfView}
        />
      )}
    </div>
  );

  const renderCallEnd = () => (
    <div className="call-end-container-1">
      <div className="call-end-container">
        <EndCallIcon className="white call-end-icon" />
        <div className="profile-pics-container">
          {invites.map((i) => {
            const user = allUserInfo[i.useruuid];
            const image = (user?.profilePics ?? {})[PhotoResolution.R64];
            return (
              <Tooltip title={i.name}>
                <div>
                  {image ? (
                    <img
                      src={image}
                      className="user-profile-pic user-profile-image"
                      alt=""
                    />
                  ) : (
                    <ContactImage className="user-profile-pic" />
                  )}
                </div>
              </Tooltip>
            );
          })}
        </div>
        <span className="header-1">{trans("CALLHASENDED")}</span>
        {eventInfo?.event_name && (
          <span className="body-text-1">{eventInfo?.event_name}</span>
        )}
        <span className="body-text-2">
          {callFormatDuration(
            moment(startTime, DateTimeFormat),
            moment(endTime, DateTimeFormat)
          )}
        </span>
        <CustomButton
          className="close-button"
          label={trans("CLOSE_U")}
          onClick={() => onEndCall({ closeModal: true })}
          icon={<CloseIcon />}
        />
      </div>
    </div>
  );

  return (
    <div className="video-call-wrapper page-content-wrapper">
      {callEnded ? (
        renderCallEnd()
      ) : (
        <Layout>
          <Layout.Content className="video-call-main-container">
            <div className="video-tiles-container">
              {renderVideoTiles()}
              {selectedOption && renderOptionsSider()}
            </div>
            <div className="video-footer-container">
              {!callWaited && (
                <div className="local-video-container">{renderSelfTile()}</div>
              )}
              <div className="call-info-actions-container">
                {renderVideoCallInfo()}
                {renderVideoCallActions()}
              </div>
            </div>
          </Layout.Content>
        </Layout>
      )}
    </div>
  );
};

VideoCallComponent.propTypes = {
  header: PropTypes.array,
  isSetupDone: PropTypes.bool,
  callStatus: PropTypes.string,
  onEndCall: PropTypes.func,
  meetingInfo: PropTypes.object,
};

export default VideoCallComponent;
