import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import "./Environmental.less";
import {
  EnvironmentalIcon,
  TemperatureIcon,
  HumidityIcon,
  AmbientLightIcon,
} from "../Common/Icons";
import EnvironmentalCard from "./EnvironmentalCard";
import { useSelector } from "react-redux";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { Spin } from "antd";
import moment from "moment";

const Environmental = ({ hubRecord, timezone }) => {
  const [tempUnit, setTempUnit] = useState(null);
  const handleTempUnitChange = (unit) => {
    setTempUnit(unit);
  };
  const [userAccessoriesDeviceuuid, setUserAccessoriesDeviceuuid] =
    useState(null);
  const [userEventsData, setUserEventsData] = useState(null);
  const [dataProcessed, setDataProcessed] = useState(false);
  const [outdoorWeather, setOutdoorWeather] = useState({});
  const [outdoorWeatherFetched, setOutDoorWeatherFetched] = useState(false);
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const weatherApi = useSelector((state) => state.apis.weatherApi);
  const trans = useSelector(translator);

  const sortByModelAndLocation = (a, b) => {
    const locationA = a[1]?.location ?? "";
    const locationB = b[1]?.location ?? "";

    if (locationA === null || locationA === undefined) return -1;
    if (locationB === null || locationB === undefined) return 1;

    return locationA.localeCompare(locationB);
  };

  const convertTemperature = (value, currentUnit, targetUnit) => {
    if (value === undefined) return null;
    if (currentUnit === targetUnit) return value;
    const toCelsius = (fahrenheit) => ((fahrenheit - 32) * 5) / 9;
    const toFahrenheit = (celsius) => (celsius * 9) / 5 + 32;

    return targetUnit === "C" ? toCelsius(value) : toFahrenheit(value);
  };

  const mapEventData = (eventData) => ({
    batteryLevel: eventData.battery_level,
    temperature: eventData.environment_status__temperature,
    tempUnit: eventData.environment_status__temperature_unit,
    humidity: eventData.environment_status__humidity,
    connectionStatus: eventData.connected ? "CONNECTED" : "DISCONNECTED",
    ambientLight: eventData.environment_status__ambient_light,
    location: eventData.location,
    timeStamp: eventData.utc_timestamp_inms,
    deviceId: eventData.environment_status__serial_number,
    modelNumber: eventData.modelnumber,
  });

  const filterEventsData = (eventsData) =>
    eventsData.filter((event) => event.modelnumber !== "HC200");

  const sortEventsData = (filteredEventsData) =>
    filteredEventsData.sort((a, b) => {
      const dateA = new Date(a.utc_timestamp_inms);
      const dateB = new Date(b.utc_timestamp_inms);
      return dateB - dateA;
    });

  const determineTempTrend = (sortedEventsData) => {
    if (sortedEventsData.length > 1) {
      const tempCurrent = sortedEventsData[0].environment_status__temperature;
      const tempPrevious = sortedEventsData[1].environment_status__temperature;
      return tempCurrent > tempPrevious ? "up" : "down";
    }
    return null;
  };

  const checkAllDevicesProcessed = (updatedData, userAccessoriesDeviceuuid) =>
    Object.keys(userAccessoriesDeviceuuid).every((deviceUuid) =>
      Object.keys(updatedData).includes(deviceUuid)
    );

  const processUserEventsData = async (deviceuuid, eventsData) => {
    if (!eventsData) return;

    setUserEventsData((currentData) => {
      const filteredEventsData = filterEventsData(eventsData);
      const sortedEventsData = sortEventsData(filteredEventsData);
      const eventData = sortedEventsData.map(mapEventData)[0];

      if (eventData !== undefined) {
        eventData.trend = determineTempTrend(sortedEventsData);
        !tempUnit && setTempUnit(eventData?.tempUnit);
      }

      const updatedData = {
        ...currentData,
        [deviceuuid]: {
          data: eventData,
          location: userAccessoriesDeviceuuid[deviceuuid].location,
        },
      };

      if (checkAllDevicesProcessed(updatedData, userAccessoriesDeviceuuid)) {
        setDataProcessed(true);
      }

      return updatedData;
    });
  };

  useEffect(() => {
    if (userEventsData && dataProcessed) {
      console.log("User Events Data -> ", userEventsData);
    }
  }, [userEventsData]);

  const fetchLatestUserEvents = (deviceuuid) => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("LatestUserEvents data", data);
      processUserEventsData(deviceuuid, data["user_events"]);
    };

    try {
      userEventsApi.getLatestUserEvents(
        hubRecord.useruuid,
        {
          deviceuuid: [deviceuuid],
          eventtypes: ["environment_status"],
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const fetchUserAccessories = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("UserAccessories data", data);

      const deviceUuids = data["user_accessories"]
        .filter((device) => device.modelnumber === "EM200")
        .reduce((acc, device) => {
          acc[device.deviceuuid] = {
            location: device.location,
            connectionStatus: device.connected ? "CONNECTED" : "DISCONNECTED",
            batteryLevel: device.battery_level,
          };
          return acc;
        }, {});

      console.log(deviceUuids);

      setUserAccessoriesDeviceuuid(deviceUuids);
    };

    try {
      userEventsApi.getUserAccessories(
        hubRecord.useruuid,
        {
          eventtypes: ["environment_status"],
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const processWeatherData = (data) => {
    if (!data.length) return;
    const weatherData = data[0];
    console.log("Weather Data", weatherData);
    tempUnit === "F"
      ? setOutdoorWeather({
          temp: Math.floor(weatherData.Temperature.Imperial.Value),
          unit: "F",
          prec: weatherData.HasPrecipitation,
          icon: weatherData.WeatherIcon,
          humidity: weatherData.RelativeHumidity,
          time: weatherData.LocalObservationDateTime,
        })
      : setOutdoorWeather({
          temp: Math.floor(weatherData.Temperature.Metric.Value),
          unit: "C",
          prec: weatherData.HasPrecipitation,
          icon: weatherData.WeatherIcon,
          humidity: weatherData.RelativeHumidity,
          time: weatherData.LocalObservationDateTime,
        });
  };

  const fetchOutdoorData = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      processWeatherData(data);
      setOutDoorWeatherFetched(true);
    };

    try {
      weatherApi.getCurrentConditions(
        { useruuid: hubRecord.useruuid },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    fetchUserAccessories();
  }, []);

  useEffect(() => {
    if (userAccessoriesDeviceuuid) {
      Object.keys(userAccessoriesDeviceuuid).forEach((deviceuuid) => {
        fetchLatestUserEvents(deviceuuid);
      });
      if (!Object.keys(userAccessoriesDeviceuuid).length) {
        setDataProcessed(true);
      }
    }
    !outdoorWeatherFetched && fetchOutdoorData();
  }, [userAccessoriesDeviceuuid]);

  return (
    <div style={{ padding: "10px" }}>
      <div className="header-container">
        <div className="header-icon-title">
          <div className="header-icon">
            <EnvironmentalIcon
              style={{ fill: "#113E57", width: "29px", height: "32px" }}
            />
          </div>
          <div className="header-title">Environmental</div>
        </div>
        <div className="header-filters">
          <div className="header-filter-content">
            <div className="header-filter-title">Temperature</div>
            <div className="header-filter-icon">
              <TemperatureIcon
                style={{
                  height: "44px",
                }}
              />
            </div>
          </div>
          <div className="header-filter-content">
            <div className="header-filter-title">Humidity</div>
            <div className="header-filter-icon">
              <HumidityIcon />
            </div>
          </div>
          <div className="header-filter-content">
            <div className="header-filter-title">Ambient Light</div>
            <div className="header-filter-icon">
              <AmbientLightIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="date-toggle-container">
        <div className="date-container">
          <div className="date-title">Latest Conditions</div>
          <div className="date-content">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </div>
        </div>
        <div className="toggle-container">
          <button
            className="temperature-button"
            style={{
              backgroundColor:
                tempUnit === "F" ? "rgba(63, 169, 245, 1)" : "grey",
              borderRadius: "30px 0 0 30px",
            }}
            onClick={() => handleTempUnitChange("F")}
          >
            F{"°"}
          </button>
          <button
            className="temperature-button"
            style={{
              backgroundColor:
                tempUnit === "C" ? "rgba(63, 169, 245, 1)" : "grey",
              borderRadius: "0 30px 30px 0",
            }}
            onClick={() => handleTempUnitChange("C")}
          >
            C{"°"}
          </button>
        </div>
      </div>
      <Spin
        spinning={!dataProcessed || !outdoorWeatherFetched}
        style={{ margin: "70px -20px" }}
      >
        {dataProcessed && outdoorWeatherFetched && (
          <div className="cards-container">
            <EnvironmentalCard
              location="Outdoor"
              temperatureVal={convertTemperature(
                outdoorWeather?.temp,
                outdoorWeather?.unit,
                tempUnit
              )}
              humidityVal={outdoorWeather?.humidity ?? null}
              outdoorWeatherIcon={outdoorWeather?.icon}
              grey={!Object.keys(outdoorWeather).length}
              timezone={timezone.split(" ")[0]}
              time={
                Object.keys(outdoorWeather).length ? outdoorWeather?.time : null
              }
            />
            {userEventsData &&
              Object.entries(userEventsData)
                .sort(sortByModelAndLocation)
                .map(([deviceUuid, eventData]) => {
                  const currentDate = new Date().toISOString().split("T")[0];
                  const eventDataExists = eventData.data !== undefined;

                  const eventDate = eventDataExists
                    ? new Date(eventData?.data?.timeStamp)
                        .toISOString()
                        .split("T")[0]
                    : null;

                  const grey = currentDate !== eventDate;

                  const convertedTemperature = eventDataExists
                    ? Math.floor(
                        convertTemperature(
                          eventData?.data?.temperature,
                          eventData?.data?.tempUnit,
                          tempUnit
                        )
                      )
                    : null;

                  const humidityVal = eventDataExists
                    ? Math.floor(eventData?.data?.humidity)
                    : null;

                  return (
                    <EnvironmentalCard
                      key={deviceUuid}
                      location={
                        eventDataExists
                          ? eventData?.data?.location
                          : userAccessoriesDeviceuuid[deviceUuid]?.location
                      }
                      deviceId={
                        eventDataExists ? eventData?.data?.deviceId : null
                      }
                      batteryStatus={
                        eventDataExists
                          ? eventData?.data?.batteryLevel
                          : userAccessoriesDeviceuuid[deviceUuid]?.batteryLevel
                      }
                      temperatureVal={convertedTemperature}
                      humidityVal={humidityVal}
                      lightVal={
                        eventDataExists ? eventData?.data?.ambientLight : null
                      }
                      connectionStatus={
                        eventDataExists
                          ? eventData?.data?.connectionStatus
                          : userAccessoriesDeviceuuid[deviceUuid]
                              ?.connectionStatus
                      }
                      time={eventDataExists ? eventData?.data?.timeStamp : null}
                      trend={eventDataExists ? eventData?.data?.trend : null}
                      timezone={timezone.split(" ")[0]}
                      grey={grey}
                    />
                  );
                })}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default Environmental;
