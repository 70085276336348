import React from "react";
import PropTypes from "prop-types";
import { AddIcon, CloseIcon } from "./Icons";
import Loading from "./Loading";
import Contact from "./Contact";

const getSelectedListStyle = (onlySelected) => {
  return {
    ...(onlySelected ? {} : { maxHeight: "50%" }),
  };
};

const ContactSelectList = ({
  style,
  className,
  fetchingContacts = false,
  displayOnlySelected = false,
  selectedListHeader = "Included Contacts",
  selectionListHeader = "Add Contacts",
  selectedContactList = [],
  selectionContactList = [],
  showAddIcon = true,
  showRemoveIcon = true,
  onAddClick = () => {},
  onRemoveClick = () => {},
  selectedContactProps = {},
  selectionContactProps = {},
  showRelationship = true,
  showAddIconCondition = () => true,
  showRemoveIconCondition = () => true,
}) => {
  return (
    <div
      style={style}
      className={`contact-selection-list-wrapper ${className ?? ""}`}
    >
      {selectedListHeader && (
        <label className="selected-header">{selectedListHeader}</label>
      )}
      <div
        className="selected-list-container"
        style={getSelectedListStyle(displayOnlySelected)}
      >
        {selectedContactList &&
          selectedContactList.map((item) => (
            <Contact
              key={item.useruuid}
              className="primary"
              contact={item}
              actionIcon={
                showRemoveIcon &&
                showRemoveIconCondition(item) && (
                  <CloseIcon
                    className="close-icon"
                    onClick={() => onRemoveClick(item)}
                  />
                )
              }
              showRelationship={showRelationship}
              {...selectedContactProps}
            />
          ))}
      </div>
      {!displayOnlySelected && (
        <>
          <div className="contacts-divider" />
          {selectionListHeader && (
            <label className="selection-list-header">
              {selectionListHeader}
            </label>
          )}
          {fetchingContacts ? (
            <Loading />
          ) : (
            <div className="selection-list-container">
              {selectionContactList?.map((item) => {
                const { addIcon, disabled, onAddIconClick } = item;
                return (
                  <Contact
                    key={item.useruuid}
                    onClick={!disabled && (onAddIconClick ?? onAddClick)}
                    contact={item}
                    actionIcon={
                      addIcon ??
                      (showAddIcon && showAddIconCondition(item) && (
                        <AddIcon className="add-icon" />
                      ))
                    }
                    showRelationship={showRelationship}
                    {...selectionContactProps}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ContactSelectList.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  fetchingContacts: PropTypes.bool,
  displayOnlySelected: PropTypes.bool,
  selectedListHeader: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  selectionListHeader: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  selectedContactList: PropTypes.arrayOf(PropTypes.object),
  selectionContactList: PropTypes.arrayOf(PropTypes.object),
  showAddIcon: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  selectedContactProps: PropTypes.object,
  selectionContactProps: PropTypes.object,
  showRelationship: PropTypes.bool,
};

export default ContactSelectList;
