/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Divider, Skeleton, Tooltip, message, Checkbox } from "antd";
import {
  HC200Icon,
  SearchIcon,
  FilterIcon,
  AccountAddIcon,
  RefreshIcon,
  FactoryResetIcon,
  SyncIcon,
  CloseIcon,
} from "../Common/Icons";
import {
  Actions,
  DeviceStatus,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import CustomDropdownCheckBox from "../Common/CustomDropdownCheckBox";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  KnownActionsList,
  isStaffActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { useSelector } from "react-redux";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import RebootResetModal from "./RebootResetModal";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import Pagination from "../Pagination/Pagination";

const DevicesComponent = ({
  header,
  deviceData,
  fetchingDeviceData,
  fetchDevices,
  providerid,
  onActionClick,
  deviceCount,
  showPagination,
  offset,
  limit,
  userHubsData
}) => {
  const [deviceList, setDeviceList] = useState(deviceData);
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchApplied, setSearchApplied] = useState(null);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const deviceManagementApi = useSelector(
    (state) => state.apis.deviceManagementApi
  );
  const trans = useSelector(translator);

  const FilterItems = [
    {
      "key": "connected",
      "type": "group",
      // label : trans("CONNECTED"),
      label : "Connected",
      "children": [
        {
          "key": "online",
          // label : trans("ONLINE"),
          label : "Online"
        },
        {
          "key": "offline",
          label : trans("OFFLINE"),
        }
      ]
    },
    {
      "key": "accounts",
      "type": "group",
      label : trans("ACCOUNTS"),
      "children": [
        {
          "key": "assigned",
          // label : trans("ASSIGNED"),
          label : "Assigned",
        },
        {
          "key": "readyToAssign",
          label : trans("READYTOASSIGN"),
        },
        {
          "key": "notActivated",
          label : trans("NOTACTIVATED"),
        },
        {
          "key": "notReady",
          label : trans("NOTREADY"),
        }
      ]
    }
  ]
  const getFilterLabel = (groupKey, itemKey) => {
    const group = FilterItems.find(group => group.key === groupKey);
    if (group) {
      const item = group.children.find(item => item.key === itemKey);
      return item ? item.label : itemKey;
    }
    return itemKey;
  };

  
  const permissions = useSelector(getPermissions());
  const currentUser = useSelector(getCurrentUser);
  const actionsData = {
    reboot: {
      title: trans("REFRESH"),
      description: trans("DEVICESREBOOTDESCRIPTION"),
      action: "device_reboot",
      icon: <RefreshIcon className="icon custom-icon-color" />,
    },
    reset: {
      title: trans("FACTORYRESET"),
      description: trans("DEVICESRESETDESCRIPTION"),
      action: "factory_reset",
      icon: <FactoryResetIcon className="icon" style={{ fill: "#005c98" }} />,
    },
  };

  useEffect(() => {
    handleSearchApply();
  }, []);

  const handleSearchToggle = () => {
    if (isSearchOn) {
      setSearchText(null);
      handleSearchClear();
    }
    setIsSearchOn((value) => !value);
  };

  const handleSearchApply = (filterKeys = appliedFilter, search = searchText) => {
    if (!Array.isArray(filterKeys)) {
      filterKeys = [];
    }
    let params = {
      filterkey: "category",
      filtervalue: "DEVICE-PRIMARY",
      sortby: "deviceid",
      orderby: "asc",
    };
  
    if (!!search) {
      const query = search.toLowerCase();
      let isDeviceId = false;
  
      if (deviceData.some((device) => device?.deviceid?.toLowerCase().includes(query))) {
        isDeviceId = true;
      }
  
      if (isDeviceId) {
        params.filterkey += ",deviceid";
        params.filtervalue += `,|${query}|`;
      } else {
        params.filterkey += ",accountname";
        params.filtervalue += `,|${query}|`;
      }
      setSearchApplied(true);
    } else {
      setSearchApplied(false);
    }
  
    const connectedFilters = filterKeys
      .filter((f) => f.groupKey === "connected")
      .map((f) => f.itemKey);
    const accountFilters = filterKeys
      .filter((f) => f.groupKey === "accounts")
      .map((f) => f.itemKey);
  
    if (connectedFilters.length > 0) {
      const connectionFilter = connectedFilters.includes("online")
        ? "CONNECTED"
        : "DISCONNECTED";
      params.filterkey += ",deviceconnectstatus";
      params.filtervalue += `,${connectionFilter}`;
    }
  
    accountFilters.forEach((itemKey) => {
      if (itemKey === "assigned") {
        params.filterkey += ",useruuid";
        params.filtervalue += ",not empty";
      } else if (itemKey === "readyToAssign") {
        params.filterkey += ",-accountname,provisioningstatus";
        params.filtervalue += ",not empty,Provisioned";
      } else if (itemKey === "notActivated") {
        params.filterkey += ",provisioningstatus,devicestatus";
        params.filtervalue += ",Unprovisioned,INACTIVE";
      } else if (itemKey === "notReady") {
        params.filterkey += ",provisioningstatus,devicestatus";
        params.filtervalue += ",Unprovisioned,ACTIVE;TO_DO_FACTORYRESET";
      }
    });
  
    fetchDevices(providerid, params, (data) => {
      setDeviceList(data);
    });
  };

  const isDataFiltered = useMemo(
    () => appliedFilter.length !== 0,
    [appliedFilter]
  );

  const handleSearchClear = () => {
    setSearchApplied(false);
    setSearchText(null);
    setDeviceList(deviceData);
  };

  const handleFilterToggle = (e) => {
    // Stop the event propagation to prevent it from reaching the parent div
    e.stopPropagation();
    setIsFilterOn((value) => !value);
  };

  const handleFilterChange = (groupKey, itemKey) => {   
    // Check if the clicked filter is already applied
    const isFilterApplied = appliedFilter.some(
      (filter) => filter.groupKey === groupKey && filter.itemKey === itemKey
    );

    let newFilters = [];

    if (isFilterApplied) {
      // If the filter is already applied, remove it
      newFilters = appliedFilter.filter(
        (filter) => !(filter.groupKey === groupKey && filter.itemKey === itemKey)
      );
    } else {
      newFilters = appliedFilter.filter((filter) => filter.groupKey !== groupKey);
      newFilters.push({ groupKey, itemKey });
    }
    setAppliedFilter(newFilters);
    handleSearchApply(newFilters, searchText);
  };


  const handleClearAllFilters = () => {
    onActionClick(null, null, offset, limit, true)();
    setAppliedFilter([]);
    handleSearchClear();
  };

  const cancelCallback = () => {
    setShowModal(false);
    setModalData({});
  };
  const refreshCallback = (data) => {
    performAction(data);
    setShowModal(false);
  };

  const handleRefresh = (device) => {
    setShowModal(true);
    let data = {
      ...actionsData.reboot,
      deviceUuid: device.deviceuuid,
      proceed: refreshCallback,
      cancel: cancelCallback,
    };
    setModalData(data);
  };

  const resetCallback = (data) => {
    performAction(data);
    setShowModal(false);
  };

  const handleReset = (device) => {
    setShowModal(true);
    let data = {
      ...actionsData.reset,
      deviceUuid: device.deviceuuid,
      proceed: resetCallback,
      cancel: cancelCallback,
    };
    setModalData(data);
  };

  const performAction = (data) => {
    const errorCallback = (error) => {
      message.error("Error performing: " + data.title + " " + error.message);
      console.error("Error performing:" + data.title + " " + error.message);
    };
    const successCallback = (info) => {
      message.success("Signal sent to Device for: " + data.title);
    };

    try {
      deviceManagementApi.performCommand(
        data.action,
        providerid,
        data.deviceUuid,
        { source: "System" },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const getDeviceStatus = (device) => {
    const isNotReadyFilterApplied = appliedFilter.some(filter => filter.itemKey === "notReady");
    const deviceStatus = device.devicestatus?.toLowerCase();
    const provisioningStatus = device.provisioningstatus?.toLowerCase();

    if (device.useruuid) {
      if (isNotReadyFilterApplied && deviceStatus === "active" && provisioningStatus !== "provisioned") {
        return DeviceStatus.active_not_provisioned;
      }
      return DeviceStatus.userassociated;
    }

    if (provisioningStatus === "provisioned") {
      return DeviceStatus.provisioned;
    }

    if (deviceStatus === "active" && provisioningStatus !== "provisioned") {
      return DeviceStatus.active_not_provisioned;
    }
    return DeviceStatus.not_activated;
  };

  const renderDeviceStatus = (device, status) => {
    return status === DeviceStatus.userassociated ? (
      <div className="account-name">{device.accountname}</div>
    ) : status === DeviceStatus.provisioned ? (
      <div className="device-status-ready-to-assign">
        <div className="device-status-text">{trans("READYTOASSIGN")}</div>
      </div>
    ) : status === DeviceStatus.active_not_provisioned ? (
      <div className="device-status-not-ready">
        <div className="device-status-text">{trans("NOTREADY")}</div>
      </div>
    ) : (
      <div className="device-status-not-activated">
        <div className="device-status-text">{trans("NOTACTIVATED")}</div>
      </div>
    );
  };

  return (
    <>
      {showModal && <RebootResetModal data={modalData} />}
      <MainPage hasSider={true} header={header}>
        <div className="devices-container page-content-wrapper">
          <div className="page-header-container">
            <span className="page-header">
              {trans("DEVICES")}
              <SyncIcon
                className="clickable-item"
                onClick={() =>handleClearAllFilters()}
              />
            </span>
            <div className="header-action-container">
              {!!appliedFilter.length && (
                <div className="applied-filter-container">
                  {appliedFilter.map((filter) => (
                    <div key={`filter_${filter.itemKey}`} className="applied-filter">
                      <span className="text">
                        {getFilterLabel(filter.groupKey, filter.itemKey)}
                      </span>
                      <CloseIcon
                        className="remove-icon"
                        onClick={() => handleFilterChange(filter.groupKey, filter.itemKey)}
                      />
                    </div>
                  ))}
                </div>
              )}
               <div className="clear-all-container" onClick={handleClearAllFilters}>
                Clear All
              </div>
              {isSearchOn && (
                <div className="search-container">
                  <Input
                    autoFocus
                    className="search-input"
                    value={searchText}
                    onChange={(e) => setSearchText(e?.target?.value)}
                    onPressEnter={handleSearchApply}
                    suffix={
                      searchApplied ? (
                        <CloseOutlined
                          onClick={handleClearAllFilters}
                          title="Clear Search"
                        />
                      ) : (
                        <span />
                      )
                    }
                  />
                  <span className="search-helper-text">
                    {trans("PRESSENTERTOSEARCH")}
                  </span>
                </div>
              )}
              <SearchIcon
                className="search-icon"
                onClick={handleSearchToggle}
              />
              <div className="filter-container" style={{ marginLeft: "0px"}}>
                {isFilterOn ? (
                  <CustomDropdownCheckBox
                    open={isFilterOn}
                    menuItems={FilterItems}
                    selectedKeys={appliedFilter}
                    handleChange={handleFilterChange}
                    onClick={handleFilterToggle}
                >
                    <div>
                      <FilterIcon className="filter-icon" />
                      <span className="text">{trans("FILTERS")}</span>
                    </div>
                  </CustomDropdownCheckBox>
                ) : (
                  <FilterIcon
                    className="filter-icon"
                    onClick={handleFilterToggle}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="item-count-container">
            <span className="item-count">
              {searchApplied || isDataFiltered
                ? `${deviceList?.length ?? 0} found | `
                : ""}
              {fetchingDeviceData ? (
                <LoadingOutlined />
              ) : (
                deviceData.length ?? 0
              )}{" "}
              {trans("TOTAL")}
            </span>
          </div>
          <div className="devices-list-container item-list-container">
            {fetchingDeviceData ? (
              <div className="device-container skeleton-container ">
                <Skeleton active avatar paragraph={false} />
              </div>
            ) : (
              deviceList.map((device) => {
                let isUserAssociated = userHubsData.some((hub) => hub.useruuid === device.useruuid);
                let status = getDeviceStatus(device);
                return (
                  <div
                    key={device.deviceuuid}
                    className="device-container item-container"
                  >
                    <div
                      className={`container-1 ${
                        status === DeviceStatus.userassociated
                          ? "device-accountname"
                          : ""
                      }`}
                      onClick={
                        (status === DeviceStatus.userassociated && isUserAssociated)
                          ? onActionClick(Actions.listHub, device, offset, limit)
                          : status === DeviceStatus.provisioned
                          ? onActionClick(Actions.assignDevice, device, offset, limit)
                          : null
                      }
                    >
                      <div
                        title={device.deviceconnectstatus}
                        className="device-icon-container"
                      >
                        <HC200Icon
                          status={device.deviceconnectstatus?.toLowerCase()}
                        />
                      </div>
                      <div className="device-sn">{device.deviceid}</div>
                      {renderDeviceStatus(device, status)}
                    </div>
                    <div className="container-2">
                      <div className="actions-container">
                        <Divider type="vertical" />
                        {[
                          DeviceStatus.provisioned,
                          DeviceStatus.active_not_provisioned,
                        ].includes(status) &&
                        isStaffActionAllowed(
                          currentUser?.permission_level,
                          KnownActionsList.manage_devices,
                          permissions
                        ) ? (
                          <div
                            className="action"
                            onClick={onActionClick(
                              Actions.assignDevice,
                              device,
                              offset,
                              limit
                            )}
                          >
                            <Tooltip title={trans("ADDACCOUNT")}>
                              <div>
                                <AccountAddIcon className="account-add-icon" />
                              </div>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="empty-action" />
                        )}
                        {![DeviceStatus.not_activated].includes(status) ? (
                          <div className="action">
                            <Tooltip title={actionsData.reboot.title}>
                              <div>
                                <RefreshIcon
                                  onClick={() => handleRefresh(device)}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="empty-action" />
                        )}
                        {![DeviceStatus.not_activated].includes(status) ? (
                          <div className="action">
                            <Tooltip title={actionsData.reset.title}>
                              <div>
                                <FactoryResetIcon
                                  onClick={() => handleReset(device)}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="empty-action" />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            {showPagination && (
              <Pagination
                deviceCount={deviceCount}
                onActionClick={onActionClick}
              />
            )}
          </div>
        </div>
      </MainPage>
    </>
  );
};

DevicesComponent.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  deviceData: PropTypes.arrayOf(PropTypes.object),
  fetchingDeviceData: PropTypes.bool,
  fetchDevices: PropTypes.func,
  onActionClick: PropTypes.func,
  providerid: PropTypes.string,
  deviceCount: PropTypes.number,
  showPagination: PropTypes.bool,
  offset: PropTypes.number,
  limit: PropTypes.number,
};

export default DevicesComponent;
