import React from "react";

export const NewContactIcon = (props) => {
  const { className = "", style = {}, onClick, disabled = false } = props;
  return (
    <svg
      className={`custom-icon ${disabled ? "disabled" : ""} ${className}`}
      onClick={disabled ? null : onClick}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_9650_11649)">
        <path
          d="M17.0014 13.3437C17.631 12.714 17.9458 11.9645 17.9458 11.0801C17.9458 10.1956 17.631 9.44609 17.0014 8.81648C16.3718 8.18687 15.6222 7.87207 14.7378 7.87207C13.8533 7.87207 13.1038 8.18687 12.4742 8.81648C11.8446 9.44609 11.5298 10.1956 11.5298 11.0801C11.5298 11.9645 11.8446 12.714 12.4742 13.3437C13.1038 13.9733 13.8533 14.2881 14.7378 14.2881C15.6222 14.2881 16.3718 13.9733 17.0014 13.3437Z"
          fill="#113E57"
          stroke="none"
        />
        <path
          d="M21.6932 18.7549C19.4146 18.7549 17.5708 20.5987 17.5708 22.8773C17.5708 25.1559 19.4146 26.9997 21.6932 26.9997C23.9718 26.9997 25.8156 25.1559 25.8156 22.8773C25.8156 20.5987 23.9718 18.7549 21.6932 18.7549ZM23.4321 23.6268H22.4428V24.6012C22.4428 25.021 22.113 25.3508 21.6932 25.3508C21.2735 25.3508 20.9437 25.021 20.9437 24.6012V23.6268H19.9693C19.5496 23.6268 19.2198 23.297 19.2198 22.8773C19.2198 22.4576 19.5496 22.1278 19.9693 22.1278H20.9437V21.1384C20.9437 20.7187 21.2735 20.3889 21.6932 20.3889C22.113 20.3889 22.4428 20.7187 22.4428 21.1384V22.1278H23.4321C23.8519 22.1278 24.1817 22.4576 24.1817 22.8773C24.1817 23.297 23.8519 23.6268 23.4321 23.6268Z"
          fill="#113E57"
          stroke="none"
        />
        <path
          d="M14.7377 24.3167C9.44597 24.3167 5.15865 20.0294 5.15865 14.7377C5.15865 9.44597 9.44597 5.15865 14.7377 5.15865C20.0294 5.15865 24.3167 9.44597 24.3167 14.7377C24.3167 15.9219 24.0918 17.0612 23.7021 18.1106C24.4066 18.4104 25.0062 18.8601 25.5159 19.3997C26.1455 17.9756 26.4903 16.3866 26.4903 14.7377C26.4753 8.26171 21.2136 3 14.7377 3C8.26171 3 3 8.26171 3 14.7377C3 21.2136 8.26171 26.4753 14.7377 26.4753C15.757 26.4753 16.7314 26.3254 17.6758 26.0856C17.2111 25.5159 16.8813 24.8413 16.6864 24.1068C16.0568 24.2417 15.3973 24.3017 14.7227 24.3017L14.7377 24.3167Z"
          fill="#113E57"
          stroke="none"
        />
        <path
          d="M20.3291 17.9158C20.0742 17.616 19.7594 17.3462 19.3697 17.1063C18.2004 16.4018 16.6564 16.042 14.7376 16.042C12.8188 16.042 11.2747 16.4018 10.1055 17.1063C8.9362 17.8109 8.35156 18.7703 8.35156 19.9695V20.764C8.35156 20.764 8.39653 20.824 8.42652 20.854C10.0455 22.473 12.2791 23.4773 14.7526 23.4773C15.3822 23.4773 15.9818 23.4174 16.5664 23.2975C16.5664 23.1625 16.5215 23.0426 16.5215 22.9077C16.5215 20.5392 18.1404 18.5454 20.3291 17.9458V17.9158Z"
          fill="#113E57"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_9650_11649">
          <rect
            width="23.4753"
            height="24"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

// Figma: new event
