/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import calling from "../../../static/images/calling.gif";
import ringtone from "../../../static/audio/ringtone.mp3";
import { useWebSocketContext } from "../WebSocket/WebsocketContext";
import { getUserInfo } from "@commscopemycloud/humaui/Store/dataStore";
import {
  handleVideoCallAccept,
  handleVideoCallReject,
  handleVideoCallTimeout,
} from "@commscopemycloud/humaui/Services/VideoCallService";
import useUserInfo from "../Hooks/useUserInfo";
import CustomButton from "../Common/CustomButton";
import { formatFullNameDisplay } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import {
  PhotoResolution,
  VideoCallNotificationTimeout,
  VideoCallStatusUpdate,
  WSMessageTypes,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { CallDeclineIcon, CallIcon, ContactImage } from "../Common/Icons";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { updateNotificationMiniBanner } from "@commscopemycloud/humaui/Store/notificationStore";

const audio = new Audio(ringtone);
audio.loop = true;

const VideoCallNotificationModal = () => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const { sendMessage } = useWebSocketContext();
  const notification = useSelector(
    (state) => state.notification.videoCallNotification
  );
  const visible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  useUserInfo({ useruuid: notification?.useruuid });
  const caller = useSelector(getUserInfo(notification?.useruuid));
  const image = useMemo(
    () => caller?.profilePics && caller?.profilePics[PhotoResolution.R128],
    [caller]
  );

  const meeting_id = useMemo(() => notification?.meeting_id, [notification]);

  useEffect(() => {
    let timer = null;
    if (visible) {
      try {
        audio?.play();
        timer = setTimeout(() => {
          handleVideoCallTimeout({
            sendMessage,
            meeting_id,
            dispatch,
          });
          dispatch(
            updateNotificationMiniBanner({
              message_type: WSMessageTypes.videoCallStatus,
              message: {
                call_status__status: VideoCallStatusUpdate.missed,
                meeting_id: meeting_id,
                event_originator_id: notification.useruuid,
                timestamp: new Date().toISOString(),
              },
            })
          );
        }, VideoCallNotificationTimeout * 1000);
      } catch (error) {
        console.error("Calling Tune Error:", error);
      }
    }
    return () => {
      audio.pause();
      clearTimeout(timer);
    };
  }, [visible]);

  const handleAccept = () => {
    console.debug("Call Accepted");
    handleVideoCallAccept({ sendMessage, meeting_id, dispatch });
  };

  const handleReject = () => {
    console.debug("Call Rejected");
    handleVideoCallReject({ sendMessage, meeting_id, dispatch });
    setTimeout(() => {
      dispatch(
        updateNotificationMiniBanner({
          message_type: WSMessageTypes.videoCallStatus,
          message: {
            call_status__status: VideoCallStatusUpdate.declined,
            meeting_id: meeting_id,
            event_originator_id: notification.useruuid,
            timestamp: new Date().toISOString(),
          },
        })
      );
    }, 500);
  };

  return (
    <>
      {calling && (
        <Modal
          open={visible}
          closable={false}
          footer={null}
          maskClosable={true}
          width={null}
          wrapClassName="vertical-center-modal video-call-notification-modal"
        >
          <header>
            <span className="">{trans("INCOMINGCALL")}</span>
          </header>
          <div className="content">
            <div className="image-container">
              {!imageLoaded && (
                <ContactImage className="placeholder-profile-pic" />
              )}
              {image && (
                <img
                  src={image}
                  className={`user-profile-pic ${
                    imageLoaded ? "show" : "hide"
                  }`}
                  alt="pic"
                  onLoad={handleImageLoad}
                />
              )}
              {calling && <img className="calling-gif" src={calling} alt="" />}
            </div>
            <span className="name">
              {caller &&
                formatFullNameDisplay(caller?.firstname, caller?.lastname)}
            </span>
            <span className="relation">{caller?.relationship || " "}</span>
          </div>
          <footer>
            <CustomButton
              label={trans("DECLINE_U")}
              icon={<CallDeclineIcon className="decline-icon" />}
              onClick={handleReject}
              style={{ marginRight: "20px" }}
            />
            <CustomButton
              label={trans("ACCEPT_U")}
              icon={<CallIcon className="accept-icon" />}
              onClick={handleAccept}
            />
          </footer>
        </Modal>
      )}
    </>
  );
};

VideoCallNotificationModal.propTypes = {};

export default VideoCallNotificationModal;
