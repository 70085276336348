import React, { createContext, useContext } from "react";
import useWebsocket from "./useWebsocket";

const WebSocketContext = createContext();

const WebSocketProvider = ({ hostname, token, children }) => {
  const socket = useWebsocket(hostname, token);

  return (
    <WebSocketContext.Provider value={{ ...socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocketContext = () => useContext(WebSocketContext);

export { WebSocketProvider, useWebSocketContext };
