/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import UserHubsComponent from "./UserHubs.component";
import {
  Actions,
  PhotoResolution,
} from "@commscopemycloud/humaui/Utilities/Constants";
import HubDetails from "../HubDetails/HubDetails";
import useUserHubs from "../Hooks/useUserHubs";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  getProfilePics,
  getUserHubs,
} from "@commscopemycloud/humaui/Store/dataStore";
import { getProfilePicUrl } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";

const UserHubs = (props) => {
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser.useruuid, [currentUser]);

  const [, fetchingUserHubs, fetchUserHubs] = useUserHubs({
    useruuid,
  });
  const userHubs = useSelector(getUserHubs(useruuid));
  const resourceMap = useSelector(getProfilePics());
  const mUserHubs = userHubs.map((hub) => ({
    ...hub,
    profilePic64: getProfilePicUrl(
      hub.useruuid,
      resourceMap,
      PhotoResolution.R64
    ),
  }));
  const mOrderedUserHubs = useMemo(() => {
    return [...mUserHubs].sort(objectSort("firstname"));
  }, [mUserHubs]);
  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const userHubCall = true;
  const sendMsgDisplay= true;

  useEffect(() => {
    if (record) {
      const newRecord = userHubs.find((a) => a.hubid === record.hubid);
      setRecord(newRecord);
    }
  }, [userHubs]);

  /* handler functions */
  const onActionClick =
    (action, record, openSubMenu, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      setOpenSubMenu(openSubMenu);
      refresh && fetchUserHubs();
    };

  return !action ? (
    <UserHubsComponent
      userHubs={mOrderedUserHubs}
      fetchingUserHubs={fetchingUserHubs}
      onActionClick={onActionClick}
    />
  ) : action === Actions.hubDetails ? (
    <HubDetails   
      hubRecord={record}
      fetchUserHubs={fetchUserHubs}     
      openSubMenu={openSubMenu}
      userHubsData={mUserHubs}
      onActionClick={onActionClick}
      sendMsgDisplay={sendMsgDisplay}
    />
  ) : action === Actions.listContacts ? (
    <HubDetails
      activeSubMenu={"contacts"}
      hubRecord={record}
      openSubMenu={openSubMenu}
      userHubCall={userHubCall}
      onActionClick={onActionClick}
      sendMsgDisplay={sendMsgDisplay}
    />
  ) : action === Actions.inviteContact ? (
    <HubDetails
      activeSubMenu={"inviteContact"}
      action={action}
      hubRecord={record}
      openSubMenu={openSubMenu}
      userHubCall={userHubCall}
      onActionClick={onActionClick}
      sendMsgDisplay={sendMsgDisplay}
    />
  ) : action === Actions.addEvent ? (
    <HubDetails
      activeSubMenu={"schedule"}
      activeSubMenuContent={"addEvent"}
      action={action}
      hubRecord={record}
      userHubsData={mUserHubs}
      openSubMenu={true}
      userHubCall={userHubCall}
      onActionClick={onActionClick}
      sendMsgDisplay={sendMsgDisplay}    
    />
  ) : action === Actions.assignStaff ? (
    <HubDetails
      activeSubMenu={"assignStaff"}
      hubRecord={record}
      openSubMenu={openSubMenu}
      userHubCall={userHubCall}
      onActionClick={onActionClick}
      sendMsgDisplay={sendMsgDisplay}
    />
  ) : action === Actions.sendMessage ? (
    <HubDetails
      activeSubMenu={"sendMessage"}
      hubRecord={record}
      openSubMenu={openSubMenu}
      userHubCall={userHubCall}
      onActionClick={onActionClick}
      sendMsgDisplay={false}
    />
  ) : null;
};

UserHubs.propTypes = {};

export default UserHubs;
