import React, { useMemo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { ExpandMoreIcon } from "./Icons";
import { Dropdown } from "antd";

const CustomDropdown = ({
  className,
  overlayClassName,
  open,
  onClick,
  disabled,
  menuItems = [],
  selectedKeys = [],
  handleChange,
  menuProps = {},
  children,
}) => {
  const [isVisible, setIsVisible] = useState(open);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsVisible(false);
        onClick(event); // Pass event object to onClick callback
      }
    };
    // Only attach the event listener when the dropdown is visible
    if (isVisible) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);

  const selectedLabel = useMemo(() => {
    const selection = menuItems.filter((a) => selectedKeys.includes(a.key));
    if (!selectedKeys.length) return "Select from options...";
    const separator = selection.length > 1 ? ", " : null;
    const label = selection.reduce((result, a, index) => {
      result +=
        result +
        a.label +
        (separator && index !== selection.length - 1 ? separator : "");
      return result;
    }, "");
    return label;
  }, [selectedKeys, menuItems]);

  return (
    <Dropdown
      overlayClassName={`custom-dropdown-style ${overlayClassName ?? ""}`}
      open={isVisible}
      disabled={disabled}
      menu={{
        items: menuItems,
        selectable: true,
        selectedKeys: selectedKeys,
        onClick: handleChange,
        ...menuProps,
      }}
    >
      <div
        ref={dropdownRef}
        className={`custom-dropdown ${className ?? ""} ${
          disabled ? "disabled" : ""
        }`}
        onClick={() => setIsVisible(!isVisible)}
      >
        <div className="dropdown-label">
          {children ? (
            children
          ) : (
            <span className="text" title={selectedLabel}>
              {selectedLabel}
            </span>
          )}
        </div>
        {isVisible ? (
          <ExpandMoreIcon className="status-icon rotate-180" />
        ) : (
          <ExpandMoreIcon className="status-icon" />
        )}
      </div>
    </Dropdown>
  );
};

CustomDropdown.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
  menuProps: PropTypes.object,
};

export default CustomDropdown;
