/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWS, { ReadyState } from "react-use-websocket";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { getMeetingInfo } from "@commscopemycloud/humaui/Store/videoCallStore";
import { processMessage } from "./WebsocketHelper";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const WEBSOCKET_URL = ENV_VARS.WEBSOCKET_URL;
const WEBSOCKET_PORT = ENV_VARS.WEBSOCKET_PORT;

const useWebsocket = (hostname, token) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const meetingInfo = useSelector(getMeetingInfo);
  const videoCallModalVisible = useSelector(
    (state) => state.videoCall.videoCallModalVisible
  );
  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );

  const useruuid = useMemo(() => currentUser.useruuid, [currentUser]);
  const WS_URL = useMemo(
    () => (WEBSOCKET_URL || "wss://" + hostname) + ":" + WEBSOCKET_PORT,
    [hostname]
  );

  const onMessage = async (e) => {
    try {
      console.info("Websocket message received:", e);
      const message = JSON.parse(e.data) || {};
      if (message.type === "pong") {
        console.info("Websocket ping response received!");
      } else {
        processMessage({
          dispatch,
          message,
          sendMessage,
          meetingInfo,
          videoCallModalVisible,
          videoCallNotificationModalVisible,
        });
      }
    } catch (error) {
      console.error("Error parsing websocket message:", error);
    }
  };

  const { sendMessage: sendMessageToWS, readyState } = useWS(WS_URL, {
    protocols: token,
    shouldReconnect: (closeEvent) => {
      return closeEvent.code !== 1000 && closeEvent.code !== 1001;
    },
    reconnectInterval: 5000 /* 5 sec */,
    reconnectAttempts: Infinity,
    heartbeat: {
      message: JSON.stringify({ type: "ping" }),
      returnMessage: JSON.stringify({ type: "pong" }),
      interval: 120 * 1000 /* 120 sec */,
      timeout: 150 * 1000 /* 150 sec */,
    },
    onOpen: (e) => {
      console.info("Websocket connection opened:", e);
    },
    onClose: (e) => {
      console.info("Websocket connection closed:", e);
    },
    onError: (e) => {
      console.error("Websocket connection error:", e);
    },
    onMessage: onMessage,
  });

  // useEffect(() => {
  //   if (useruuid && readyState === ReadyState.OPEN) {
  //     sendMessage({ type: "client_id", id: useruuid }, "client_id");
  //     console.info("Websocket message sent to server!", useruuid);
  //   }
  // }, [useruuid, readyState]);

  const sendMessage = (message, type = "message") => {
    console.debug("Websocket message tobe sent:", message);
    /* ToDo: message to be zipped and encrypted */
    const msgObject = { type, id: useruuid, message };
    if (readyState !== ReadyState.OPEN) {
      console.error(
        "Websocket connection is not open, can't send message!",
        readyState
      );
      return;
    }
    sendMessageToWS(JSON.stringify(msgObject));
  };
  return { sendMessage, readyState };
};

export default useWebsocket;
