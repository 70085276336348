import React from "react";
import {
  getName,
  objectSort,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { VideoCallStatusUpdate } from "@commscopemycloud/humaui/Utilities/Constants";
import { CallDeclineIcon, MissedCallIcon } from "../Common/Icons";
import moment from "moment";

export const filterNotifications = (notifList = []) => {
  let list = [];
  notifList.forEach((notification) => {
    if (
      notification.call_status__status === VideoCallStatusUpdate.declined ||
      notification.call_status__status === VideoCallStatusUpdate.missed ||
      notification.call_status__status === VideoCallStatusUpdate.accepted
    ) {
      //const obj = processNotification(notification, useruuid);
      //obj && list.push(obj);
      list.push(notification);
    }
  });
  list = list.sort((a, b) => objectSort("utc_timestamp_inms")(b, a));
  return list;
};

export const processNotifications = (notifList = [], currentHub) => {
  if (!currentHub) return {};
  let listObj = {};
  notifList.forEach((notification) => {
    const {
      useruuid,
      call_status__status,
      invited_contacts,
      event_type,
      event_originator_id,
      utc_timestamp_inms,
    } = notification;
    let obj = {};

    if (
      invited_contacts.length === 2 &&
      invited_contacts.some((c) => c.useruuid === currentHub?.useruuid)
    ) {
      let otherUser = (invited_contacts ?? []).find(
        (c) => c.useruuid !== currentHub?.useruuid
      );

      if (otherUser.useruuid in listObj) return;

      if (call_status__status === VideoCallStatusUpdate.declined)
        obj.icon = <CallDeclineIcon className="event-icon grey" />;
      else if (call_status__status == VideoCallStatusUpdate.missed)
        obj.icon = <MissedCallIcon className="event-icon red" />;

      let desc = "";
      if (event_originator_id === currentHub?.useruuid) {
        desc += getName(currentHub) + " called";
      } else desc += "Called " + getName(currentHub);

      const timestamp = moment(utc_timestamp_inms);
      const isToday = timestamp.isSame(moment(), "day");
      const isYesterday = timestamp.isSame(moment().subtract(1, "day"));
      if (isToday) {
        desc += " Today at " + timestamp.format("h:mm A");
      } else if (isYesterday) {
        desc += " Yesterday at " + timestamp.format("h:mm A");
      } else {
        desc +=
          " on " +
          timestamp.format("dddd") +
          " at " +
          timestamp.format("h:mm A");
      }
      obj.desc = desc;
      listObj[otherUser.useruuid] = obj;
    }
  });
  return listObj;
};
